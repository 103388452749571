@import 'src/basestyles/variables';

// Make the map flexibly fill the available vertical space
.map-page-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .map-wrapper {
    flex: 1;

    // reset default dms box shadow on in-map buttons
    button:hover,
    button:focus {
      box-shadow: none;
    }

    // reset default label on in-map settings
    label {
      display: inline-block;
      font-family: $font-family;
      font-weight: normal;
      font-size: $font-size-large;
      color: $font-color;
      margin-left: $space-xs;
      margin-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
