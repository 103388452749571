// Tables

.table-responsive {
  width: 100%;
  overflow-y: hidden;
  margin-bottom: $space-md;
  @include scroll-horizonal;

  @media print {
    margin-bottom: $space-xs;
    overflow: visible; // needed for multi-page pdf <th> overlap
  }

  > table {
    margin-bottom: 0;
  }

  tbody tr {
    @media print {
      page-break-inside: avoid;
    }

    &:nth-child(odd) {
      background-color: $table-row-background-odd;

      @media print {
        background-color: transparent;
      }
    }

    &:nth-child(even) {
      background-color: $table-row-background-even;

      @media print {
        background-color: transparent;
      }
    }

    &.table-row-error,
    &.table-row-danger,
    &.status-parse_error,
    &.status-processing_error {
      background-color: $table-row-danger-background-odd;

      &:nth-child(even) {
        background-color: $table-row-danger-background-even;
      }
    }

    &.table-row-warning,
    &.table-row-mute {
      background-color: $table-row-warning-background-odd;

      &:nth-child(even) {
        background-color: $table-row-warning-background-even;
      }
    }

    &.table-row-mute {
      .icon-mute {
        color: $warning-color;
      }
    }

    &:last-child {
      border-bottom: 2px solid $border-color;
    }

    &.table-row-icon {
      td:first-child {
        position: relative;
        padding-left: $table-row-icon-padding;

        @media print {
          padding-left: 25px;
        }

        &::before {
          @include icon-insert;
          font-size: $icon-dimension-base;
          position: absolute;
          top: $table-cell-padding;
          left: $table-cell-padding;

          @media print {
            font-size: $font-size-base;
            top: $space-xs;
            left: $space-xs;
          }
        }
      }

      &.table-row-error td:first-child::before,
      &.table-row-danger td:first-child::before {
        content: $icon-exclamation-solid;
        color: $danger-color;
      }

      &.table-row-warning td:first-child::before {
        content: $icon-exclamation-solid;
        color: $warning-color;
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  max-width: 100%;

  caption {
    font-family: $font-family-secondary;
    line-height: $line-height-header;
    font-size: ($font-size-base * 1.5); // match h2
    font-weight: bold;
    text-align: left;
    margin-bottom: $space-md;
  }

  th,
  td {
    text-align: left;
    border: 0;
    padding: $table-cell-padding;
    line-height: $icon-dimension-base;
    vertical-align: top;

    @media print {
      padding: $space-xs;
      border: 1px solid $border-color;
      font-size: $font-size-small;
      line-height: $line-height;
    }

    a {
      @media print {
        color: $font-color;
      }
    }
  }

  thead {
    @media print {
      display: table-header-group;
    }

    th {
      @include text-secondary-light;
      color: $font-color-secondary;
      border-top: 2px solid $border-color;
      border-bottom: 2px solid $border-color;
      vertical-align: middle;

      @media print {
        color: $font-color;
      }

      .table-header-secondary {
        display: block;
        @include text-secondary-bold;
        font-size: $font-size-small;
        line-height: 1.2em;
        margin-top: $space-xs;
      }

      &.table-header-sortable {
        cursor: pointer;

        div {
          display: flex;
          align-items: center;

          &:hover,
          &:focus {
            color: $link-color-hover;
          }
        }

        [class*='icon-sort'] {
          color: $link-color;
          font-size: 18px;
          margin-left: 4px;

          @media print {
            font-size: $font-size-base;
            line-height: 1em;
            color: $font-color;
          }
        }

        [class='icon-sort'] {
          @media print {
            display: none;
          }
        }
      }
    }
  }

  tfoot {
    @media print {
      display: table-row-group;
    }
  }

  // table cell status
  .table-cell-error,
  .table-cell-danger,
  .table-cell-warning {
    &::before {
      @include icon-insert;
      margin-right: $space-xs;
      content: $icon-exclamation-solid;
      color: $danger-color;
    }
  }

  .table-cell-warning::before {
    color: $warning-color;
  }

  .table-cell-error-multi span {
    display: block;

    &::before {
      @include icon-insert;
      margin-right: $space-xs;
      content: $icon-exclamation-solid;
      color: $danger-color;
    }
  }

  fieldset {
    margin: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: $space-xs;

    @media print {
      margin-bottom: 0;
    }
  }

  ul {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
  }

  textarea {
    width: 100%;
    min-width: 100px;
    min-height: 0px;
    margin: 0;
    padding-top: 3px;
    padding-bottom: 0;
    resize: vertical;
  }

  // Draggable table row
  .draggable {
    cursor: url('../assets/images/openhand.cur'), move !important; // need for IE cursor style

    .table-responsive & {
      &:hover {
        background-color: $green-faint;

        td:first-child::before {
          color: $link-color-hover;
        }
      }
    }

    td:first-child {
      position: relative;
      padding-left: $table-row-icon-padding;

      &::before {
        @include icon-insert;
        content: $icon-reorder;
        color: $icon-color;
        font-size: $icon-dimension-base;
        display: inline-block;
        position: absolute;
        top: $table-cell-padding;
        left: $table-cell-padding;
        vertical-align: top;
      }
    }
  }

  .dragging {
    color: $green-dark;
    background-color: $green-faint;
    display: table;
    pointer-events: auto !important; // need for IE cursor style
    cursor: url('../assets/images/closedhand.cur'), move !important; // need for IE cursor style

    &:nth-child(odd),
    &:nth-child(even) {
      background-color: $green-faint;
    }

    td:first-child::before {
      color: $link-color-hover;
    }
  }

  // Striped rows reversed
  &.table-stripe-reverse tbody tr {
    &:nth-child(odd) {
      background-color: $white;

      @media print {
        background-color: transparent;
      }
    }

    &:nth-child(even) {
      background-color: $grey-light;

      @media print {
        background-color: transparent;
      }
    }
  }

  // table states: empty, loading, error
  .td-no-results {
    @include text-feature;
    padding: $space-xxl 20% 75px;
    background-color: $table-empty-background-color;

    .alert-danger .alert-header {
      display: block;
      text-align: left;
    }
  }

  // pagination loading, opacity on table when navigating
  &[data-is-loading='true'] {
    tbody td:not(.td-no-results) {
      opacity: 0.5;
    }
  }

  td {
    transition: opacity $transition-duration-base;
  }

  // cell takes up minimal width required, doesn't grow
  .table-cell-min-width {
    width: 1%;
  }

  // set max width for cell
  .table-cell-max-width {
    max-width: 50%;
  }

  // cell text wrap on td only, e.g. for long file name with no spaces
  td.table-cell-wrap {
    word-break: break-word;
  }
}
