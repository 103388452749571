// Plots

// Page-level plot info
.plot-page-header-info {
  display: flex;
  align-items: center;

  h2 {
    font-size: ($font-size-base * 1.285); // same as h3
  }

  h2,
  h3,
  p {
    margin-top: 0;
    margin-bottom: $space-xs;
  }
}

// Plotted content
line {
  &.rv-xy-plot__grid-lines__line {
    stroke-width: 1px;
    shape-rendering: crispEdges;
    fill: transparent;
  }

  &.rv-xy-plot__axis__line {
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }

  &.rv-xy-plot__axis__tick__line {
    stroke-width: 1px;
    shape-rendering: crispEdges;
  }
}

// plot labels
.rv-xy-plot__axis__title text,
.plot-label-inside {
  fill: $grey-mid-dark;
  text-transform: uppercase;
  font-size: 10px;
}

.plot-label-inside {
  font-style: italic;
  &.annotation-label {
    text-transform: none;
    font-style: normal;
  }
}

// annotation line
.plot-annotation-line {
  stroke-width: 1px;
}

// axis label increment colour
.rv-xy-plot__axis__tick__text {
  fill: $font-color;
}

// x axis label position
.xAxis .rv-xy-plot__axis__tick__text {
  text-anchor: start;
}

.yAxis text.rv-xy-plot__axis__tick__text {
  // RM#72148: Shift vertical alignment of tick labels down by 1/2 font height,
  // to avoid them getting cut off at the top of the plot.
  dominant-baseline: central;
}

// plot line
path.plot-readings-line {
  stroke-width: 1px;
}

// A secondary wrapper around the plot, without "overflow:hidden",
// for positioning the datatable.
.non-cropping-plot-wrapper {
  position: relative;
}

// Plot area
.plot-area {
  width: 100%;
  margin: 0;
  overflow: hidden;
  position: relative;

  @media print {
    page-break-inside: avoid; // avoid breaking plot onto two pages
    height: 500px;
  }

  &.plot-area-scatter {
    max-width: 550px;
  }
}

// Plot legend
.plot-legend {
  margin-bottom: $space-xs;
  font-size: $font-size-small;
  line-height: 15px;

  @media print {
    page-break-inside: avoid; // avoid breaking section onto two pages
  }

  :last-child {
    margin-bottom: 0;
  }

  dl,
  ol {
    margin-top: 0;

    @media #{$large-and-above} {
      display: flex;
      flex-wrap: wrap;
    }

    div {
      display: flex;
      margin-bottom: 2px; // custom spacing

      @media #{$large-and-above} {
        display: inline-flex;
        padding-right: $space-lg;
      }

      &.loading {
        opacity: 0.5;
      }
    }

    dt,
    dd,
    li {
      display: inline-block;
      margin-top: 0;
      margin-bottom: $space-xs;
      vertical-align: top;
    }

    dt,
    li {
      font-family: $font-family;
      font-weight: normal;
      color: $font-color;
      white-space: nowrap;
      margin-right: $space-xs;
      margin-bottom: 0;

      span {
        font-size: $font-size-base;
        font-weight: bold;
        line-height: 100%;
      }
    }
  }

  // timeseries plot specific
  &.plot-legend-timeseries {
    ol {
      padding-left: 0;
    }

    ol div {
      @media #{$large-and-above} {
        width: 50%;
      }

      @media #{$x-large-and-above} {
        width: 33.33%;
      }

      @media #{$xx-large-and-above} {
        width: 25%;
      }

      // if one item, use 100% width
      &:nth-last-child(1):first-child,
      &:nth-last-child(1):first-child ~ div {
        @media #{$large-and-above} {
          width: 100%;
        }
      }

      // if two items, use 50% width
      &:nth-last-child(2):first-child,
      &:nth-last-child(2):first-child ~ div {
        @media #{$large-and-above} {
          width: 50%;
        }
      }
    }
  }

  // survey levelling plot specific
  &.plot-legend-survey-levelling {
    dl div {
      display: inline-flex;
      min-width: 110px;
    }
  }
}

.scatter-plot-latest-reading-icon {
  font-family: $icon-font;
  fill: yellow;
  font-size: $icon-dimension-large;
  // use multiple text shadows to create a "border"
  text-shadow: 0px -1px 2px #333333, 1px 0px 2px #333333, 0px 1px 2px #333333,
    -1px 0px 2px #333333;
}

.scatter-plot-minimum-legend,
.scatter-plot-maximum-legend {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 3px;
  margin-right: 10px;
}

.scatter-plot-minimum-legend {
  background: orange;
}

.scatter-plot-maximum-legend {
  background: red;
}

g.plot-markers,
span.plot-marker-legend {
  font-family: $icon-font;
  font-size: 12px;
}

.scatter-plot-highlight-marker {
  font-family: $icon-font;
  font-size: 10px;
}

.scatter-plot-highlight-legend {
  ol {
    padding-left: 0;
  }

  li {
    display: inline-block;
    margin-top: 0;
    margin-bottom: $space-xs;
    vertical-align: top;
    font-family: $font-family;
    font-weight: normal;
    color: $font-color;
    white-space: nowrap;
    margin-right: 1em;

    span {
      font-size: 12px;
      line-height: 100%;
    }
  }

  .highlight-legend-marker {
    font-family: $icon-font;
    font-size: 14px !important;
  }
}

.scatter-plot-highlight-legend-ssr {
  position: relative;
  float: left;

  ol {
    padding: 0;
    list-style-type: none;
  }
  li {
    color: $font-color;
    span {
      font-size: 10px;
      line-height: 100%;
    }
  }

  .highlight-legend-marker {
    font-family: $icon-font;
    font-size: 12px !important;
  }
}
