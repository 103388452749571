@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.react-select.column-select {
  top: auto;
  margin-bottom: $space-lg;

  @media #{$medium-and-above} {
    max-width: 250px;
  }

  @media #{$large-and-above} {
    margin-bottom: 0;
  }

  .react-select__control {
    height: calc(#{$input-min-height} + 2px); // vertical align in IE
  }

  .react-select__placeholder {
    color: $font-color;
  }

  .react-select__menu {
    margin-top: $dropdown-offset-top;
  }

  .react-select__menu-list {
    padding-left: 6px;
    padding-right: 6px;
    @include scroll-vertical;
  }

  .react-select__option {
    color: $font-color;
    border-radius: $border-radius;
    padding-top: 4px;
    padding-bottom: 4px;
    margin-top: 3px;
    margin-bottom: 3px;

    .react-select-inner-wrap {
      position: relative;
      padding-left: #{$radio-check-input-padding-x};

      // unchecked
      &::before {
        position: absolute;
        content: '';
        border-radius: $border-radius-small;
        border: 1px solid $radio-check-input-color;
        width: $radio-check-input-size;
        height: $radio-check-input-size;
        background-color: $white;
        top: 2px;
        left: 0;
      }

      .icon-tick {
        position: absolute;
        font-size: $check-icon-size;
        color: $white;
        top: 2px;
        left: 2px;
      }
    }
  }

  .react-select__option--is-focused {
    background-color: transparent;
  }

  .react-select__option--is-selected {
    background-color: $grey-light;

    .react-select-inner-wrap::before {
      border-color: $radio-check-input-color-checked;
      background-color: $radio-check-input-color-checked;
    }
  }
}
