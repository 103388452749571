// PDF specific styles
// see also StoredPlot.scss

.page .pdf-plot-page .main-content main {
  padding: 0;
}

.pdf-plot-page {
  // Page level headers
  .page-header h1 {
    font-size: $font-size-base;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }

  .pdf-page-sub-header {
    font-size: $font-size-small;
  }

  // Plotted content
  .rv-xy-plot__borders rect {
    stroke: white !important; // hide addtional line rendering on generated pdfs
  }

  .rv-xy-plot__series--label-text,
  .plot-port-rl-label {
    font-size: 9px; // A, B, C, etc. & inner port label
  }

  // Plot area
  .plot-area {
    height: auto;
  }

  .pdf-interpolated-message {
    margin-top: 0;
    margin-bottom: 0;
  }

  // Plot legend
  .plot-legend {
    margin: 0;
    font-size: 10px;

    dl,
    ol {
      margin: 0;
    }

    // timeseries plot specific
    &.plot-legend-timeseries {
      > ol > div {
        display: inline-block;
        padding-right: $space-lg;
        width: 33.33%;
        vertical-align: top;
        white-space: nowrap;
        overflow: hidden;

        // if one item, use 100% width
        &:nth-last-child(1):first-child,
        &:nth-last-child(1):first-child ~ div {
          width: 100%;
        }

        // if two items, use 50% width
        &:nth-last-child(2):first-child,
        &:nth-last-child(2):first-child ~ div {
          width: 50%;
        }
      }
    }

    // survey levelling specific
    &.plot-legend-survey-levelling {
      dl div {
        display: inline-block;
        min-width: 0;
        width: 10%; // fit 10 on a row
        padding-right: $space-lg;
      }
    }

    &.plot-legend-cumulative {
      > ol > div {
        display: inline-block;
        padding-right: 20px;
      }
    }

    dd {
      display: inline;
    }
  }
}
