// CSS variables

$site-min-width: 340px;
$log-in-min-height: 560px;

// Responsive breakpoints
$medium-and-above: 'only screen and (min-width: 768px)';
$large-and-above: 'only screen and (min-width: 992px)';
$x-large-and-above: 'only screen and (min-width: 1420px)'; // enough for card with two columns, and nav expanded
$xx-large-and-above: 'only screen and (min-width: 1600px)';

// Colours

$white: #ffffff;
$grey-faint: #f8f8f8;
$grey-light: #efefef;
$grey-mid: #cfcfcf;
$grey-mid-dark: #6c6c6c;
$grey-dark: #333333;

$blue-faint: #f6f9fa;
$blue-light: #8dcedc;
$blue-mid: #327572;
$blue-mid-dark: #33565e;
$blue-dark: #274248;
$blue-bright: #2466ab;

$blue-grey: #5c7f87;
$teal: #088185;
$green-grey-light: #e0eaea;

$green-faint: #e8f1eb;
$green-light: #b2d9bf;
$green-mid: #4da167; // "algae"
$green-mid-dark: #3f8454;
$green-dark: #136e38;

$yellow: #e69219;
$yellow-dark: #a15b24;
$orange-faint: #fff3e2;
$orange: #fc9f1a;
$red-faint: #fff3f3;
$red-light: #d93939;
$red-mid: #b71616;

$success-color: $green-mid-dark;
$danger-color: $red-mid;
$danger-color-secondary: $red-faint;
$warning-color: $yellow-dark;
$warning-color-secondary: $orange-faint;
$info-color: $blue-bright;

// Utilities

$background-color: $white;
$border-color: $grey-mid;
$border-radius-small: 3px;
$border-radius: 4px;
$border-radius-large: 18px;
$border-radius-pdf: 10px;

$box-shadow-xs: 0 0 2px 0 rgba(0, 0, 0, 0.2);
$box-shadow-small: 0 0 5px 0 rgba(0, 0, 0, 0.15);
$box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.15);
$box-shadow-button-inner: inset 0 0 5px 0 rgba(0, 0, 0, 0.2);
$box-shadow-nav-flyout: 3 0px 3px 0 rgba(0, 0, 0, 0.15);
$transition-duration-base: 0.2s;

$space-xs: 5px;
$space-sm: 10px;
$space-md: 15px;
$space-lg: 20px;
$space-xl: 30px;
$space-xxl: 60px;

// Text

$font-family: 'Open Sans';
$font-family-secondary: 'Roboto Slab';
$font-family-header: $font-family-secondary;

$font-weight-light: 300;

$font-size-base: 14px;
$font-size-small: 12px;
$font-size-x-small: 10px;
$font-size-large: 16px;

$font-color: $grey-dark;
$font-color-secondary: $grey-mid-dark;
$font-color-disabled: $grey-mid;

$line-height: 1.428; // 14px = 20px
$line-height-header: 1.3;

$link-color: $green-dark;
$link-color-hover: $green-dark;
$link-color-focus: $blue-dark;

$text-small-caps-color: $blue-mid;

$icon-font: 'icons' !important;
$icon-dimension-base: 20px; // default non-inherited size
$icon-dimension-large: 24px;
$icon-color: $grey-mid-dark;

// Form/inputs

$button-padding-x: 17px;
$button-primary-color: $white;
$button-primary-background: $blue-dark;
$button-primary-background-hover: $blue-mid;
$button-min-height: 36px;
$button-badge-min-dimension: 16px;

$input-padding-y: 7px;
$input-padding-x: $space-sm;
$input-icon-spacing-x: 44px;
$input-border-color-focus: $green-mid-dark;
$input-min-height: $button-min-height;

$radio-check-input-size: 15px;
$radio-check-input-color: $grey-mid;
$radio-check-input-color-hover: $green-mid-dark;
$radio-check-input-color-checked: $green-mid-dark;
$radio-check-input-color-disabled: $grey-light;
$radio-check-padding-y: $space-sm;
$radio-check-padding-x: $radio-check-input-size;
$radio-check-input-padding-x: calc(#{$radio-check-input-size} + 9px);
$radio-check-line-height: $radio-check-input-size;
$check-icon-size: 11px;

$radio-inline-padding-y: 7px;
$radio-inline-padding-x: $space-md;
$radio-inline-line-height: $icon-dimension-base;
$radio-inline-color-checked: $white;
$radio-inline-background-color: $white;
$radio-inline-background-color-checked: $blue-dark;
$radio-inline-background-color-checked-disabled: $grey-mid-dark;
$radio-inline-border-color: $border-color;
$radio-inline-border-color-hover: $grey-mid-dark;
$radio-inline-border-color-checked: $radio-inline-border-color-hover;
$radio-condensed-padding-y: 2px;
$radio-condensed-line-height: 20px;

$form-background-color: #eceff1; // custom colour
$form-background-color: $blue-faint;
$form-group-interval-select-width: 115px;
$form-group-interval-select-padding: calc(
  #{$form-group-interval-select-width} + #{$space-md}
);

// Areas

$loading-circle-width: 70px;
$loading-circle-border-width: 6px;

$nav-width-expanded: 240px;
$nav-width-collapsed: 60px;
$nav-collapse-duration: 100ms;
$nav-block-padding-x: $space-sm;
$nav-block-padding-x-large: $space-lg;
$nav-border-color: $blue-grey;

$modal-panel-background: $form-background-color;
$modal-width: 400px;
$modal-padding: $space-lg;

$panel-background: $grey-light;
$panel-width: $site-min-width;
$panel-width-large: 730px;
$panel-padding: $space-lg;
$panel-large-label-width: 170px;

$sidebar-width: 245px; // fits default input[type='file'] text: "Browse... No file selected", as used on dashboard file upload

$card-padding: $space-lg;
$card-padding-header-y: $space-md;
$card-margin-bottom: $space-lg;

$card-form-section-header-width-md: 160px;
$card-form-section-header-color: $blue-dark;

$card-col-1-width-lg: 430px;
$card-col-1-width-xl: 510px;
$card-col-1-label-width-xl: 200px;
$card-col-1-content-width-xl: 280px;

$card-col-2-width-lg: $card-col-1-width-lg;
$card-col-2-width-xl: 390px;
$card-col-2-label-width-xl: 90px;

$card-compact-col-1-width-lg: 480px;
$card-compact-col-1-width-xl: 300px;
$card-compact-col-1-label-width-xl: 120px;
$card-compact-col-1-content-width-xl: 150px;

$card-compact-col-2-and-3-width-lg: $card-col-1-width-lg;
$card-compact-col-2-and-3-width-xl: 270px;
$card-compact-col-2-and-3-label-width-xl: 60px;

$table-row-background-odd: $grey-light;
$table-row-background-even: $white;
$table-row-danger-background-odd: #efe4e4; // custom colour
$table-row-danger-background-even: $danger-color-secondary;
$table-row-warning-background-odd: #efe4d4; // custom colour
$table-row-warning-background-even: $warning-color-secondary;
$table-row-icon-padding: 40px;
$table-cell-padding: $space-sm;
$table-empty-background-color: #e2e2e2; // custom colour

$pagination-dimension: 28px;
$pagination-border-radius: 14px; // half of $pagination-dimension — calc not working in IE
$pagination-padding: $space-xs;
$pagination-border-width: 1px;
$pagination-line-height: 16px;

$dropdown-offset-top: 8px;

$functional-block-background: $grey-faint;
$functional-block-background-active: $green-faint;

$popup-padding: $space-sm 12px;

// Z-indexes

$z-index-modal: 4;
$z-index-nav-collapsed-flyout: 3;
$z-index-nav: 2;
$z-index-header-content: 1;

// Icons - create var only if using via CSS
$icon-exclamation-solid: '\e911';
$icon-info-solid: '\e913';
$icon-tick-solid: '\e91c';
$icon-processing: '\e91a';
$icon-cross: '\e923';
$icon-arrow-rounded-left: '\e924'; // svg positioned a bit different, right aligned (nav active)
$icon-upload: '\e932';
$icon-arrow-left: '\e903';
$icon-arrow-right: '\e904';
$icon-arrow-up: '\e905';
$icon-arrow-down: '\e902';
$icon-arrow-double-left: '\e900';
$icon-arrow-double-right: '\e901';
$icon-reorder: '\e938';
$icon-tick: '\e931';
