// Form elements

fieldset {
  padding: 0;
  border: 0;
  margin: $space-md 0 $space-xl;
  min-width: 100%;

  fieldset {
    margin-bottom: 0;
  }
}

legend {
  @include text-secondary-bold;
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: $space-md;

  [class^='icon-'] {
    margin-right: $space-xs;
  }
}

.form-group {
  margin-top: $space-md;
  margin-bottom: $space-md;

  fieldset & {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

label {
  display: block;
  @include text-secondary-light;
  margin-bottom: $space-xs;
  color: $font-color-secondary;

  &::after {
    content: ':';
  }

  [class^='icon-'] {
    margin-right: $space-xs;
  }
}

input,
select,
textarea {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  font-family: $font-family;
  font-size: $font-size-base;
  line-height: 20px;
  padding: $input-padding-y $input-padding-x;

  &:focus {
    box-shadow: $box-shadow-small;
    border-color: $input-border-color-focus;
  }
}

textarea {
  width: 100%;
  background-color: $background-color;
  min-height: 100px;
  position: relative; // IE11 fix — prevent textarea content overlapping react-select dropdown

  &:focus {
    border-color: $input-border-color-focus;
  }
}

input[type='submit'],
input[type='submit'].btn-primary {
  padding-left: $button-padding-x;
  padding-right: $button-padding-x;
}

input[type='file']:hover {
  cursor: pointer;
}

.check-group-wrapper,
.radio-group-wrapper {
  clear: both;

  .check-group,
  .radio-group {
    position: relative;

    label {
      display: inline-block;
      font-family: $font-family;
      line-height: $radio-check-line-height;
      color: $font-color;
      width: 100%;
      padding: $radio-check-padding-y $radio-check-padding-x;
      padding-left: $radio-check-input-padding-x;
      margin: 0;

      &::after {
        content: ''; // remove colons
      }

      // unchecked
      &::before {
        position: absolute;
        content: '';
        border: 1px solid $radio-check-input-color;
        width: $radio-check-input-size;
        height: $radio-check-input-size;
        background-color: $white;
        left: 0;
      }
    }

    input {
      position: absolute;
      margin: 0;
      width: $radio-check-input-size;
      height: 100%;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: 1;

      &:hover {
        cursor: pointer;

        + label {
          cursor: pointer;

          &::before {
            border-color: $radio-check-input-color-hover;
          }
        }
      }

      &:focus {
        + label::before {
          box-shadow: $box-shadow-small;
        }
      }

      // checked
      &:checked {
        + label::before {
          border-color: $radio-check-input-color-checked;
        }
      }

      &:disabled {
        + label {
          color: $font-color-disabled;

          &::before {
            border-color: $radio-check-input-color-disabled;
          }

          &:hover {
            cursor: default;

            &::before {
              border-color: $radio-check-input-color-disabled;
            }
          }
        }
      }
    }
  }

  // radio specific
  .radio-group {
    // unchecked
    label::before {
      border-radius: 50%;
    }

    // checked
    input:checked + label::before {
      border-width: 5px;
    }
  }

  // check specific
  .check-group {
    // unchecked
    label::before {
      border-radius: $border-radius-small;
    }

    // checked
    input:checked + label::before {
      @include icon-insert;
      content: $icon-tick;
      font-size: $check-icon-size;
      line-height: calc(#{$radio-check-input-size} - 2px); // minus border
      text-align: center;
      color: $white;
      background-color: $radio-check-input-color-checked;
    }
  }
}

// checkbox: button style
.checkbox-toggle-wrapper {
  label {
    font-size: $font-size-small;
    line-height: $radio-inline-line-height;
    text-align: center;
    background-color: $radio-inline-background-color;
    padding: $radio-inline-padding-y $radio-inline-padding-x;
    width: 100%;
    transition: background-color $transition-duration-base ease,
      border $transition-duration-base ease;
    vertical-align: top;

    &::before {
      display: none;
    }

    &::after {
      content: '';
    }

    border-radius: $border-radius;
    border: 1px solid $radio-inline-border-color;
  }

  input {
    display: none;
    &:hover + label {
      transition: border $transition-duration-base ease;
      border-color: $radio-inline-border-color-hover;
    }

    &:focus + label {
      box-shadow: $box-shadow-small;
    }

    &:checked + label {
      border-color: $radio-inline-border-color-checked;
      background-color: $radio-inline-background-color-checked;
      color: $radio-inline-color-checked;
      transition: background-color $transition-duration-base ease,
        border $transition-duration-base ease;
    }
  }

  [class^='icon-'] {
    font-size: $radio-inline-line-height;
    vertical-align: top;
  }
}

// radio: button toggle-style
fieldset.radio-fieldset-toggle {
  .radio-group-wrapper {
    display: flex;
  }

  .radio-group {
    width: 100%;

    label {
      font-size: $font-size-small;
      line-height: $radio-inline-line-height;
      text-align: center;
      background-color: $radio-inline-background-color;
      border: 1px solid $radio-inline-border-color;
      border-left: 0;
      padding: $radio-inline-padding-y $radio-inline-padding-x;
      width: 100%;
      transition: background-color $transition-duration-base ease,
        border $transition-duration-base ease;
      vertical-align: top;

      &::before {
        display: none;
      }
    }

    &:first-of-type {
      label {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        border-left: 1px solid $radio-inline-border-color;
      }

      input {
        &:hover + label::after {
          display: none;
        }
      }
    }

    &:last-of-type {
      label {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    input {
      &:hover + label {
        transition: border $transition-duration-base ease;
        border-color: $radio-inline-border-color-hover;

        // fake left border
        &::after {
          content: '';
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $radio-inline-border-color-hover;
          top: 0;
          left: -1px;
        }
      }

      &:disabled {
        cursor: default;
      }

      &:disabled + label {
        border-color: $radio-inline-border-color;
        &::after {
          opacity: 0;
        }
      }

      &:focus + label {
        box-shadow: $box-shadow-small;
      }

      &:checked + label {
        border-color: $radio-inline-border-color-checked;
        background-color: $radio-inline-background-color-checked;
        color: $radio-inline-color-checked;
        transition: background-color $transition-duration-base ease,
          border $transition-duration-base ease;
      }

      &:checked:disabled + label {
        background-color: $radio-inline-background-color-checked-disabled;
      }
    }

    [class^='icon-'] {
      font-size: $radio-inline-line-height;
      vertical-align: top;
    }
  }
}

// radio: smaller height
fieldset.radio-fieldset-condensed {
  .radio-group label {
    line-height: $radio-condensed-line-height;
    padding-top: $radio-condensed-padding-y;
    padding-bottom: $radio-condensed-padding-y;

    // unchecked
    &::before {
      top: 4px;
    }
  }
}

// radio: with inline legend
fieldset.radio-fieldset-inline {
  legend {
    width: auto;
    font-weight: normal;
    color: $font-color;
    margin-top: 8px;
    margin-right: $space-md;
    margin-bottom: 0;
  }

  // chrome and ie don't support flex on fieldset, so we achieve
  // an inline legend & fieldset by not clearing the legend's float
  .radio-group-wrapper {
    clear: none;
  }

  + .radio-fieldset-inline {
    margin-top: $space-sm;
  }
}

// radio: both condensed and inline, used on quick plot > settings (L/R)
fieldset.radio-fieldset-condensed.radio-fieldset-inline {
  legend {
    min-width: 100px;
    margin-top: 4px;
    margin-right: $space-xl;
  }
}

// file input: delete button beside file name
// note: different layout if in a card
.file-input-wrapper {
  display: flex;
  align-items: flex-start;
  word-break: break-word;
  padding-top: 7px;

  button {
    margin-left: $space-md;
    flex-shrink: 0;
    margin-top: -7px;
  }
}

.input-unit-wrapper {
  position: relative;
  display: inline-block;

  input {
    padding-right: $input-icon-spacing-x;
  }

  .input-unit {
    position: absolute;
    right: 1px;
    top: 1px;
    width: $input-min-height; // square
    text-align: center;
    line-height: 34px;
    background-color: $grey-faint;
    font-size: $font-size-small;
    border-radius: 0 $border-radius $border-radius 0;
  }
}

select {
  background-color: $background-color;
  appearance: none; // remove default dropdown arrow
  text-overflow: ellipsis;
  padding-top: calc(#{$input-padding-y});
  padding-bottom: calc(#{$input-padding-y});
  padding-right: $input-min-height; // square
  background-image: url('../assets/images/select-arrow.svg');
  background-repeat: no-repeat;
  background-position: 100% 50%;
  max-width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border-color: $input-border-color-focus;
    background-image: url('../assets/images/select-arrow-focus.svg');
  }

  // remove dropdown on ie
  &::-ms-expand {
    display: none;
  }

  option:hover {
    cursor: pointer;
  }
}

.react-select {
  max-width: 100%;

  .react-select__placeholder {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
  }

  // drop down
  .react-select__indicators {
    align-items: flex-start;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__dropdown-indicator {
    background-image: url('../assets/images/select-arrow.svg');
    background-repeat: no-repeat;
    width: $icon-dimension-large;
    height: $icon-dimension-large;
    padding: 0;
    margin-top: 8px;

    svg {
      display: none;
    }
  }

  // container - before active
  .react-select__control:hover {
    cursor: pointer;
  }

  // container - active
  .react-select__control--is-focused {
    box-shadow: $box-shadow-small;
    border-color: $input-border-color-focus;

    &:hover,
    &:focus {
      border-color: $input-border-color-focus;
    }

    .react-select__dropdown-indicator {
      background-image: url('../assets/images/select-arrow-focus.svg');
    }
  }

  // text - before active
  .react-select__value-container {
    padding: 4px $space-sm;

    &.react-select__value-container--is-multi.react-select__value-container--has-value {
      padding: $space-xs $space-xs 0;
    }
  }

  // reset default
  .react-select__input input {
    box-shadow: none;
  }

  // options
  .react-select__menu {
    box-shadow: $box-shadow;

    &::after {
      @include indicator-arrow-up;
    }
  }

  .react-select__option {
    color: $grey-mid-dark;
    background-color: transparent;
  }

  .react-select__option--is-focused {
    background-color: $green-dark;
    color: $white;
    border-radius: $border-radius;
    cursor: pointer;
  }

  .react-select__option--is-selected {
    color: $font-color;
    background-color: $green-faint;
  }

  // multi select
  .react-select__multi-value {
    background-color: $green-faint;
    box-shadow: $box-shadow-xs;
    margin: 0 $space-xs $space-xs 0;
  }

  .react-select__multi-value__label {
    font-size: $font-size-base;
    color: $green-dark;
    border-radius: $border-radius;
    padding-left: $space-sm;
    padding-right: $space-xs;
  }

  &.clear-disallowed .react-select__multi-value__label {
    padding-right: $space-sm;
  }

  .react-select__multi-value__remove {
    padding-left: 9px;
    padding-right: 9px;
    transition: background-color $transition-duration-base ease;

    &::before {
      @include icon-insert;
      content: $icon-cross;
      font-size: 7px;
      color: $green-dark;
    }

    svg {
      display: none;
    }

    &:hover {
      background-color: $green-light;
    }
  }

  &.clear-disallowed .react-select__multi-value__remove {
    display: none;
  }
}

option {
  padding-left: 0;
  padding-right: 0;
}

.form-group-interval-select {
  .form-group-content {
    position: relative;
    padding-right: $form-group-interval-select-padding;

    input[type='text'] {
      width: 100%;
    }

    select {
      position: absolute;
      top: 0;
      right: 0;
      width: $form-group-interval-select-width;
    }
  }
}

// Error text

.error {
  color: $danger-color;
  font-family: $font-family-secondary;
  font-weight: bold;
  font-size: $font-size-small;
  margin-top: $space-xs;
  margin-bottom: $space-xs;
  position: relative;
  word-break: break-word;

  &::before {
    @include icon-insert;
    content: $icon-exclamation-solid;
    margin-right: $space-xs;
  }

  &:last-of-type {
    margin-bottom: 12px;
  }
}
