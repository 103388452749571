@import 'src/basestyles/variables';

.preview-media {
  img,
  video {
    margin: 5px;
    vertical-align: top;
    border: 1px solid $blue-dark;
  }

  button,
  button:hover {
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: inherit;
    background-color: inherit;
  }

  button {
    span {
      padding: 0;
    }
  }
}

.preview-media-modal {
  img,
  video {
    margin: 0px;
    vertical-align: top;
    border: 1px solid $blue-dark;
    width: 100%;
  }
}

.preview-audio-thumbnail-icon {
  margin: 6px;
  font-size: 128px !important;
  line-height: 110% !important;
}
