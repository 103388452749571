@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.forgot-password-form {
  width: 100%;
  padding: $space-md;

  h2 {
    position: relative;
    display: inline-block;
    padding-bottom: $space-md;
    margin-top: 0;
    margin-bottom: 0;

    &::after {
      @include text-underline-insert;
      background-color: $green-mid-dark;
    }
  }

  form {
    border-top: 2px solid $green-mid-dark;
    padding-top: $space-md;
    position: relative;

    label {
      position: relative;
      height: $icon-dimension-large;
      margin: 6px 0 6px 6px;
      color: $white;

      &::after {
        content: '';
      }

      [class^='icon-'] {
        font-size: $icon-dimension-large;
        line-height: $icon-dimension-large;
        color: $grey-mid;
      }
    }

    input[type='text'],
    input[type='password'] {
      width: 100%;
    }

    button {
      background-color: $green-mid-dark;
      margin-top: $space-md;

      &:hover,
      &:focus {
        background-color: $green-dark;
      }
    }
    .btn-primary {
      margin-right: $space-md;
    }
  }

  .alert {
    margin-top: $space-md;
    margin-bottom: $space-md;
  }
}
