@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.upload-media-areas {
  position: relative;

  label {
    margin-right: 80px; // space for select button
  }
}

.upload-media-areas-select-all {
  @include button-reset;
  font-family: $font-family;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;

  span {
    padding: 0;
    color: $link-color;
  }
}
