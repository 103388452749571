.performance-indicator-responses-edit-button {
  float: right;
  margin-left: 10px;
  span {
    font-size: 18px;
  }
}

.performance-indicator-response-option {
  label {
    white-space: nowrap;
  }
}
