@import 'src/basestyles/variables';

#card-section-field-system_role_description {
  width: calc(100%);
  .form-group-content {
    width: auto;
  }
  .alert-header {
    font-size: $font-size-base;
  }
}

.user-area-groups-form-card-section {
  .group-delete-button {
    float: right;
  }
}
