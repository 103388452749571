// Advanced filters modal
@import 'src/basestyles/variables';

.report-filter-modal {
  fieldset {
    margin-top: 0;

    + fieldset {
      border-top: 1px solid $border-color;
      padding-top: $space-md;
    }
  }

  label {
    @media #{$medium-and-above} {
      width: $panel-large-label-width;
      padding-top: 7px;
      padding-right: $space-md;
      margin-bottom: 0;
    }
  }

  .form-group {
    @media #{$medium-and-above} {
      display: flex;
    }
  }

  .form-group-content {
    @media #{$medium-and-above} {
      width: calc(100% - #{$panel-large-label-width});
    }
  }

  select {
    width: 100%;
  }

  // exclude date and time related inputs
  input[type='text']:not([class^='DateInput-']),
  .react-select {
    @media #{$medium-and-above} {
      width: 300px;
    }
  }

  // Date options
  .form-date-range {
    @media #{$medium-and-above} {
      display: flex;
      align-items: flex-start;
    }

    > select {
      margin-bottom: $space-xs;

      @media #{$medium-and-above} {
        width: 170px;
        flex-shrink: 0;
        flex-grow: 0;
        margin-right: $space-md;
        margin-bottom: 0;
      }
    }

    .date-between-and {
      display: block;
      line-height: 24px;

      @media #{$medium-and-above} {
        flex-grow: 1;
        line-height: $input-min-height;
        text-align: center;
        width: $input-min-height;
      }
    }

    // within the next/within the last
    .form-date-interval {
      display: flex;

      @media #{$medium-and-above} {
        width: calc(100% - #{$panel-large-label-width});
      }

      input[type='text'] {
        margin-right: $space-md;

        @media #{$medium-and-above} {
          width: auto;
        }
      }
    }
  }
}
