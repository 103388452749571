// Panels

// use .panel-context-wrapper if we are positioning the panel within the page content
.panel-context-wrapper {
  display: flex;

  > div:first-child {
    flex: 1;
    overflow: hidden;
  }

  .panel-wrapper {
    margin-left: $space-xl;
    margin-right: -#{$space-xl};
    margin-bottom: -#{$space-xxl}; // currently only used at bottom of page

    .panel {
      height: calc(100% + #{$space-xxl});
    }
  }
}

.panel {
  background-color: $panel-background;
  padding: $panel-padding;

  .panel-header {
    border-bottom: 1px solid $border-color;
    position: relative;
    padding-right: $space-xl; // space for close icon
    margin-bottom: $space-md;

    h2,
    h3 {
      display: inline-block;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: $space-md;

      &::after {
        @include text-underline-insert;
      }
    }
  }

  .panel-subheader {
    font-size: $font-size-base;
    color: $blue-dark;

    span:not(:last-child)::after {
      content: '';
      width: 1px;
      height: $font-size-base;
      display: inline-block;
      background-color: $blue-dark;
      margin-left: 8px;
      margin-right: $space-xs;
      vertical-align: middle;
    }
  }

  legend {
    @include text-small-caps;
  }

  table {
    margin-top: $space-xl;

    thead th {
      border-top: 0;
    }
  }

  // Inline label/inputs: Date inputs (DD/MM/YYYY) & quicklist radio toggle (text is longer)
  .form-group-panel-inline {
    display: flex;
    justify-content: space-between;
    clear: both;

    .radio-fieldset-toggle {
      min-width: auto;
      width: 160px;
      margin-top: 0;
    }

    label {
      padding-top: 7px;
      padding-right: $space-md;
      margin-bottom: 0;
    }

    .DateInput-wrapper {
      flex-shrink: 0;
    }
  }

  // Inline label/radio field toggle for short text, e.g. yes/no, show/hide
  // Can't use flex, as flex isnt supported on a <fieldset>
  .radio-fieldset-inline {
    legend {
      width: calc(100% - 125px); // toggle width + right margin

      &::after {
        content: ':';
      }
    }

    .radio-group-wrapper {
      margin-top: 0;
      width: 110px;

      label {
        padding-left: $space-xs;
        padding-right: $space-xs;
      }
    }
  }
}

// Tabs/form in a panel - to update in future stories
.tabs-wrapper,
.panel-form-wrapper {
  margin-top: $space-lg;
  margin-bottom: $space-md;

  .tab-head {
    display: flex;
    align-items: flex-start;

    .btn-tab {
      cursor: pointer;
      margin-bottom: $space-xs;
      margin-right: $space-sm;

      &:last-of-type {
        margin-right: 0;
      }

      &.active {
        @include text-secondary-bold;
        position: relative;
        color: $blue-dark;
        background-color: $form-background-color;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        cursor: default;
        padding-bottom: 12px;
        border-bottom-color: $form-background-color;
        margin-bottom: 0;

        &:hover,
        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .tab-body,
  .panel-form-body {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    background-color: $form-background-color;
    padding: 0 $panel-padding;
    margin-top: -1px;
    margin-left: -$panel-padding;
    margin-right: -$panel-padding;

    legend {
      font-family: $font-family-header;
      font-size: $font-size-base;
      color: $blue-dark;
      text-transform: none;
    }
  }
}

// Member list
.panel-member-list {
  list-style-type: none;
  padding-left: 0;

  > li {
    border-top: 1px solid $border-color;
    padding-top: $space-md;
    padding-bottom: $space-md;
  }
}
