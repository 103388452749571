@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

table.member-table {
  @include text-small;
  margin-top: $space-sm;

  caption {
    @include text-secondary-light;
    font-size: $font-size-base;
    color: $font-color-secondary;
    margin-top: $space-md;
    margin-bottom: $space-md;

    &::after {
      content: ':';
    }
  }

  th[scope='row'] {
    width: 115px;
    padding-top: 0;
    padding-bottom: 0;
    line-height: 24px;
  }

  td {
    padding: 0;
    line-height: 24px;
  }

  // if inside a form
  .panel-form-wrapper & {
    color: $font-color;

    th[scope='row'] {
      padding-left: 0;
    }
  }
}
