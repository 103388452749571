@import 'src/basestyles/variables';

fieldset.radio-toggle-data-table {
  display: inline;
  min-width: 220px; // state due to floated legend
  margin-top: 0;
  margin-left: $space-md;
  margin-bottom: $space-xs;

  legend {
    white-space: nowrap;
  }

  div.radio-group {
    width: inherit;

    label {
      font-size: $font-size-base;
      width: inherit;
    }

    &:first-of-type {
      label {
        border-top-left-radius: $border-radius-large;
        border-bottom-left-radius: $border-radius-large;
      }
    }

    &:last-of-type {
      label {
        border-top-right-radius: $border-radius-large;
        border-bottom-right-radius: $border-radius-large;
      }
    }
  }
}

.data-table-wrapper-wander {
  position: absolute;
  top: $space-lg;
  left: $space-lg;
}

.data-table {
  background-color: $white;
  padding: $space-xs;
  border: 1px solid $border-color;

  caption {
    line-height: 1em;
    font-size: $font-size-small;
    text-align: center;
    margin-bottom: $space-xs;
  }

  th,
  td {
    font-size: $font-size-small;
    line-height: 1em;
    padding: $space-xs;
  }

  thead th {
    font-family: $font-family;
    color: $font-color;
  }
}

// Cross section "adjacent piezo" connection lines
path.cross-section-connection-line {
  stroke-width: 1px;
  stroke: blue;
  stroke-dasharray: 10 5;
}

// Cross section icons/labels - Observation point
.cross-section-obs-point-label {
  font-size: $font-size-small;
}

.cross-section-obs-point-icon {
  font-family: $icon-font;
  fill: aqua;
}

.cross-section-obs-point-value {
  fill: red;
  font-size: $font-size-x-small;
}

// Cross section icons/labels - Port RL
.cross-section-port-rl-icon {
  font-family: $icon-font;
  fill: lime;
}

// Cross section icons/labels - Lake/tail water level
.cross-section-water-level-icon {
  fill: blue;
}

.cross-section-water-level-value {
  fill: blue;
  font-size: $font-size-x-small;
}

.cross-section-water-level-line {
  stroke: blue;
}

.cross-section-water-level-text {
  fill: blue;
  font-size: $font-size-x-small;
}

// Spatial plan icons/labels
.spatial-plan-obs-point-icon {
  font-family: $icon-font;
  fill: yellow;
}

.spatial-plan-obs-point-text {
  fill: yellow;
  font-size: $font-size-x-small;
  // use multiple text shadows to create a "border"
  text-shadow: 0px -1px 2px black, 1px 0px 2px black, 0px 1px 2px black,
    -1px 0px 2px black;
}

.spatial-plan-lake-tail-icon {
  font-family: $icon-font;
  fill: white;
}

.spatial-plan-lake-tail-label {
  fill: white;
  font-size: $font-size-x-small;
}

// Spatial wander icons/labels
.wander-deformation-marker-icon {
  font-family: $icon-font;
  fill: red;
}

.wander-deformation-marker-label {
  fill: red;
  font-size: $font-size-x-small;
  // use multiple text shadows to create a "border"
  text-shadow: 0px -1px 2px white, 1px 0px 2px white, 0px 1px 2px white,
    -1px 0px 2px white;
}

.wander-line {
  // NOTE: Colors for these lines have to be defined
  stroke: black;
  stroke-width: 1px;

  &.latest-survey {
    stroke: red;
  }
}

.wander-error-ellipse {
  stroke-width: 1px;
  fill: none;

  .initial-survey {
    stroke: red;
  }

  .previous-survey {
    stroke: purple;
  }

  .latest-survey {
    stroke: lime;
  }
}

.movement-vector-scale {
  path,
  rect {
    stroke: black;
    stroke-width: 0.2px;
  }

  path {
    fill: none;
  }
  rect {
    fill: white;
  }

  text {
    fill: black;

    &.movement-vector-scale-label,
    &.movement-vector-scale-units-label {
      // use multiple text shadows to create a "border"
      text-shadow: 0px -1px 2px white, 1px 0px 2px white, 0px 1px 2px white,
        -1px 0px 2px white;
    }

    &.movement-vector-scale-label {
      font-weight: bold;
    }
  }
}

.scale-rule {
  path,
  rect {
    stroke: black;
    stroke-width: 0.2px;
  }

  path {
    fill: none;
  }
  rect {
    fill: white;
  }

  text {
    fill: black;

    &.scale-rule-label {
      // use multiple text shadows to create a "border"
      text-shadow: 0px -1px 2px white, 1px 0px 2px white, 0px 1px 2px white,
        -1px 0px 2px white;
    }
  }
}

.readings-legend {
  path,
  rect {
    stroke: white;
    stroke-width: 0.2px;
  }

  path {
    fill: none;
  }
  rect {
    fill: #545454;
  }

  text {
    fill: white;

    &.readings-legend-symbol {
      font-family: 'icons', sans-serif !important;
    }
  }
}
