/**
 * TODO: Resize the text editor to when the file is small?
 * @see https://gist.github.com/tureki/9505359
 */
.ace_editor {
  border: 1px solid lightgray;
  height: 400px;
  width: 100%;
}

.syntaxError {
  position: absolute;
  border-bottom: 1px red dotted;
}
