@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.stored-plot-general-card {
  border: 0;

  &.card-active {
    background-color: transparent;
    box-shadow: none;
  }
}

.stored-plot-plot-card {
  &.card-active {
    background-color: transparent;
  }

  .card-footer {
    padding-bottom: $card-padding;
  }

  .annotation-delete-button {
    float: right;
  }
}

.card.card-observation-point-item {
  position: relative;
  background-color: $white;

  &::after {
    @include card-left-border;
  }

  .card-header {
    display: block;

    @media #{$medium-and-above} {
      display: flex;
      justify-content: flex-start;
    }

    h2 {
      @include card-header-secondary;
      flex-shrink: 0;

      @media #{$medium-and-above} {
        margin-bottom: 0;
      }

      @media #{$x-large-and-above} {
        margin-right: 0;
        padding-right: $space-md;
        width: calc(
          #{$card-form-section-header-width-md} + #{$card-col-1-label-width-xl}
        );
      }

      .error:last-of-type {
        margin-bottom: 0;
      }
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-grow: 2;

      .action-block {
        margin-left: $space-md;
      }
    }
  }

  .react-select {
    width: 100%;

    @media #{$medium-and-above} {
      width: $card-col-1-content-width-xl;
    }
  }
}

.spatial-plot-observation-points {
  margin-left: -#{$card-padding};
  margin-right: -#{$card-padding};
  padding: $card-padding;
  padding-top: 0px;
  padding-bottom: 0px;

  .btn-add-card {
    border: 1px solid $border-color;
    margin-bottom: 0;
  }
}

.card-section.stored-plot-spatial-fieldset {
  padding-bottom: 0px;
  padding-top: 0px;
}

table.stored-plot-spatial-table th,
table.stored-plot-spatial-table td {
  min-width: 100px;
  &:nth-child(1) {
    min-width: 180px;
  }
  &.action-icons {
    min-width: 75px;
  }
  &.reading-input {
    min-width: 150px;
  }
  &.reading-date-input {
    min-width: 290px;
  }
  .spatial-plot-table-select-input {
    min-width: 150px;
  }
  .wander-plot-table-select-input {
    min-width: 200px;
  }
  .label-input {
    max-width: 150px;
  }
  .number-input {
    max-width: 100px;
  }
}

.stored-plot-spatial-add-observation-point {
  margin-bottom: 20px;
}

table.stored-plot-series-table th,
table.stored-plot-series-table td {
  min-width: 100px;
  &:nth-child(1) {
    min-width: 330px;
  }
  &:nth-child(2) {
    min-width: 110px;
  }
  &.action-icons {
    min-width: 75px;
  }
}
