@import 'src/basestyles/variables';

.comment-panel-action-block {
  display: flex;

  // tighten padding to fit comment buttons on same line
  button span,
  .btn span {
    padding-left: 12px;
    padding-right: 12px;
  }

  .btn-tab {
    margin-right: $space-sm;
  }

  .comment-report-link {
    margin-left: auto;
  }
}
