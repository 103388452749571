@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.accordion-container {
  position: relative;
}

.accordion-toggle {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px $card-padding;
  text-decoration: none;
  @include text-small-caps;
  font-size: 13px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $link-color;

  &[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }

  &:hover,
  &:focus {
    color: $link-color-hover;
    text-decoration: none;

    button {
      box-shadow: $box-shadow-small;
    }
  }

  button {
    margin-left: $space-md;

    [class^='icon-'] {
      color: $link-color;
    }
  }

  .accordion-title-additional-content {
    flex-grow: 2;
    text-align: right;
    margin-left: $space-md;
  }

  a.btn {
    font-size: $font-size-base;
    text-transform: none;
  }
}

.accordion-content-collapsed {
  display: none;
}

.accordion-content {
  padding: $space-xs $card-padding $card-padding;
  border: 1px solid $border-color;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-top: 0;

  ul {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Secondary style
.accordion-secondary {
  align-self: flex-start;
  margin-bottom: $space-lg;

  .accordion-toggle {
    font-family: $font-family-secondary;
    font-size: 18px;
    text-transform: none;
    color: $blue-mid-dark;
    background-color: $green-grey-light;

    &[aria-expanded='true'] {
      background-color: transparent;

      button {
        background-color: $green-faint;
      }
    }
  }

  .table-responsive {
    margin-bottom: 0;
  }
}

// in card footer
.card-footer .accordion-container {
  .accordion-toggle,
  .accordion-content {
    border: 0;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
  }
}
