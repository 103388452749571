@import 'src/basestyles/variables';

.quickplotsettings-y-axis {
  fieldset.radio-fieldset-inline {
    legend {
      width: 142px; // custom
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: $space-md;
    }
  }
}
