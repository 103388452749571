@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.login-page,
.forgot-password-page {
  flex-direction: column;
  position: relative;
  background-color: $blue-mid;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: 50%;
  }

  &::before {
    background-image: url('../../assets/images/log-in-feature-small.png');
    background-repeat: no-repeat;
    background-size: cover, contain;
    filter: blur(8px);

    @media #{$medium-and-above} {
      background-image: url('../../assets/images/log-in-feature-large.png');
    }
  }

  &::after {
    background-image: url('../../assets/images/log-in-contour-small.png');
    background-repeat: repeat;
    top: 0;

    @media #{$medium-and-above} {
      background-image: url('../../assets/images/log-in-contour-large.png');
    }
  }

  .login-page-main,
  .forgot-password-page-main {
    padding: $space-xl $space-md $space-xl;
    display: flex;
    flex-grow: 2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;

    h1 {
      color: $white;
      width: 100%;
      margin-top: 0;
      text-shadow: 0px 0px 3px rgba(51, 51, 51, 0.75); // $font-color ($grey-dark) with opacity

      @media #{$medium-and-above} {
        width: 540px;
      }
    }

    .forgot-password-form form label {
      position: relative;
      height: $icon-dimension-large;
      margin: 6px 0 6px 0;
      border-right: none;
    }

    .login-form-wrapper,
    .forgot-password-form-wrapper {
      display: flex;
      background: $blue-dark;
      color: $white;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      width: 100%;

      @media #{$medium-and-above} {
        width: 540px;
      }
    }

    .login-form {
      width: 100%;
      padding: $space-md;

      @media #{$medium-and-above} {
        width: 350px;
        padding: $space-lg $space-lg $space-xl;
      }

      h2 {
        position: relative;
        display: inline-block;
        padding-bottom: $space-md;
        margin-top: 0;
        margin-bottom: 0;

        &::after {
          @include text-underline-insert;
          background-color: $green-mid-dark;
        }
      }

      form {
        border-top: 2px solid $green-mid-dark;
        padding-top: $space-md;
        position: relative;

        label {
          position: absolute;
          height: $icon-dimension-large;
          margin: 6px 0 6px 6px;
          border-right: 1px solid $grey-mid;
          &::after {
            content: '';
          }

          [class^='icon-'] {
            font-size: $icon-dimension-large;
            line-height: $icon-dimension-large;
            color: $grey-mid;
          }
        }

        input[type='text'],
        input[type='password'] {
          width: 100%;
          padding-left: $input-icon-spacing-x;
        }

        button {
          background-color: $green-mid-dark;
          margin-top: $space-md;

          &:hover,
          &:focus {
            background-color: $green-dark;
          }
        }
        a.forgot-password {
          margin-top: $space-md;
          float: right;
          color: $blue-light;
        }
      }

      .alert {
        margin-top: $space-md;
        margin-bottom: $space-md;
      }
    }

    .login-feature-image {
      flex-shrink: 0;
      position: relative;
      max-height: 350px;
      width: 0px;
      transition: width $transition-duration-base ease;
      overflow: hidden;

      @media #{$medium-and-above} {
        width: 240px;
      }

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: $space-xl;
        background: linear-gradient(
          to bottom,
          rgba(30, 87, 153, 0) 0%,
          rgba(38, 68, 80, 1) 80%,
          rgba(39, 66, 72, 1) 100%
        );
      }

      @media #{$medium-and-above} {
        display: flex;
      }

      img {
        position: absolute;
        width: 100%;
      }
    }
  }

  footer {
    z-index: 1;
    padding: $space-md;

    @media #{$medium-and-above} {
      padding: $space-lg;
    }
  }
}
