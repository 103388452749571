@import 'src/basestyles/variables';

.alarm-parameter-table {
  caption {
    font-size: $font-size-large;
  }
  table {
    margin-bottom: 20px;
  }
}

.alarm-parameters-tables-heading {
  float: left;
}

#alarm-parameter-time-interval-interval-number {
  width: 50%;
}
