@import 'src/basestyles/variables';

.plot-zoom {
  cursor: crosshair;

  rect {
    fill: $green-mid; // highlighted area colour
  }
}

// Plot alarm parameters. I place two classes onto each plot element for an
// alarm parameter, to represent its "type" (min/max), and its "level".
// - .plot-alarm-level-[data-check|alert|design-check]
// - .plot-alarm-type-[minimum|maximum]
.plot-alarm-line {
  stroke-width: 1px;
}

// "data-check" is the innermost alarm level
.plot-alarm-level-data-check {
  &.plot-alarm-line {
    stroke: $blue-mid-dark;
    stroke-dasharray: 5 5;
  }
  &.plot-alarm-background {
    fill: #f3fafb; // 10% of $blue-light;
  }
}

// "alert" is the middle alarm level
.plot-alarm-level-alert {
  &.plot-alarm-line {
    stroke: $orange;
    stroke-dasharray: 3 1;
  }
  &.plot-alarm-background {
    fill: #fff5e8; // 10% of $orange
  }
}

// "design-check" is the outermost alarm level
.plot-alarm-level-design-check {
  &.plot-alarm-line {
    stroke: $green-mid;
    stroke-dasharray: 2 5;
  }
  &.plot-alarm-background {
    fill: #edf5f0; // 10% of $green-mid
  }
}
