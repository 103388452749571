@import 'src/basestyles/variables';

.action-block {
  margin-top: $space-md;
  margin-bottom: $space-md;

  @media print {
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    margin: 8px $space-md 8px 0;
  }

  button,
  .btn {
    margin-right: $space-sm;
    margin-bottom: $space-xs;
    vertical-align: top;

    @media print {
      display: none;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  .react-select {
    margin-right: $space-sm;
  }

  &.text-right {
    button,
    .btn {
      margin-right: 0;
      margin-left: $space-sm;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &.filtered-table-options {
    text-align: right;
    margin-left: $space-md;
    margin-bottom: $space-lg;

    @media print {
      display: none;
    }

    @media #{$medium-and-above} {
      margin-left: $space-xl;
      float: right;
    }
  }
}
