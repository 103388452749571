div .rfip {
  margin: 0px;

  .rfipicons__cp {
    width: 32px !important;
  }

  .rfipbtn {
    width: 280px;
    border-radius: 4px;
    min-height: 40px;
  }

  .rfipbtn__current {
    flex: inherit;
  }

  .rfipbtn__icon {
    margin-left: 7px;
  }

  .rfipbtn__icon--empty {
    text-transform: none;
  }
}

.font-picker-icon {
  font-size: 1.5em;
  text-shadow: 0px 0px 2px grey;
}
