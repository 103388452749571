#card-section-survey-points {
  .radio-fieldset-toggle {
    label {
      white-space: nowrap;
    }
  }

  .survey-point-label-column {
    width: 200px;
  }

  .survey-point-label-field {
    width: 100px;
  }

  .survey-point-show-label-column {
    width: 210px;
  }

  .survey-point-distance-column {
    width: 320px;

    label {
      width: 100px;
    }
  }
}
