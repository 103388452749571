// Buttons, button class
@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

button,
.btn,
a.btn {
  @include text-secondary-light;
  color: $font-color;
  padding: 2px;
  border: 1px solid $border-color;
  border-radius: $border-radius-large;
  min-height: $button-min-height;
  background-color: $white;
  display: inline-flex;
  align-items: center;
  text-align: center;
  text-decoration: none; // remove underline on link
  transition: background-color $transition-duration-base ease,
    border $transition-duration-base ease;
  cursor: pointer;
  position: relative;
  overflow: visible; // render badge outside of the area in IE

  &:hover,
  &:focus {
    box-shadow: $box-shadow-small;
  }

  &:active {
    box-shadow: $box-shadow-button-inner;
  }

  &:disabled {
    opacity: 0.6;
  }

  [class^='icon-'] {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: $icon-dimension-base;
    color: $icon-color;
    border-radius: 15px;
    padding: 0;
    flex-shrink: 0;
    align-self: start;
  }

  span {
    padding: $space-xs $button-padding-x;

    &:not(.visuallyhidden) {
      // if text & icon - icon on right only
      ~ [class^='icon-'] {
        margin-left: -18px;
        margin-right: 6px;
      }
    }
  }

  &.btn-primary {
    @include text-highlight-reverse;
    background-color: $button-primary-background;
    font-weight: bold;
    color: $button-primary-color;
    border: 0;
    padding: 3px;

    &:hover,
    &:focus {
      background-color: $button-primary-background-hover;
    }

    &:active {
      background-color: $button-primary-background;
    }

    // override base styles
    [class^='icon-'] {
      background-color: $button-primary-color;
      color: $green-mid-dark;
    }

    // if text & icon - icon on right only
    span:not(.visuallyhidden) {
      ~ [class^='icon-'] {
        margin-left: -8px;
        margin-right: 0;
      }
    }
  }

  &.btn-secondary {
    background-color: $grey-light;
    border: 0;
    padding: 3px;

    &:hover,
    &:focus {
      background-color: $grey-faint;
    }
  }

  &.btn-danger {
    @include text-highlight-reverse;
    background-color: $danger-color;
    font-weight: bold;
    color: $white;
    border: 0;
    padding: 3px;

    &:hover,
    &:focus {
      background-color: $red-light;
    }

    &:active {
      background-color: $danger-color;
    }

    // override base styles
    [class^='icon-'] {
      background-color: $white;
      color: $red-mid;
    }

    // if text & icon - icon on right only
    span:not(.visuallyhidden) {
      ~ [class^='icon-'] {
        margin-left: -8px;
        margin-right: 0;
      }
    }
  }

  // Styling for the button that has activated the info panel
  &.btn-panel-active {
    box-shadow: $box-shadow-button-inner;
  }

  // Info panel button styled like a link
  &.btn-link-panel {
    background-color: transparent;
    border-radius: 0;
    border: none;
    padding: 0;
    display: inline-block;
    min-height: 0;

    [class^='icon-'] {
      line-height: $icon-dimension-base;
      width: $icon-dimension-base;
      height: $icon-dimension-base;
    }

    &:hover,
    &:focus,
    &.btn-panel-active {
      box-shadow: none;

      [class^='icon-'] {
        color: $link-color;
      }
    }

    &.btn-panel-active::after {
      content: '';
      width: calc(100% + 6px);
      height: 4px;
      display: block;
      background-color: $link-color;
      position: absolute;
      left: -3px;
      bottom: -6px;
    }
  }

  // Tab button
  &.btn-tab {
    border-top-left-radius: $border-radius-small;
    border-bottom-left-radius: $border-radius-small;
  }

  // Badge
  .button-badge {
    text-align: center;
    background-color: $blue-mid;
    font-family: $font-family;
    font-size: 9px;
    color: $white;
    min-width: $button-badge-min-dimension;
    height: $button-badge-min-dimension;
    line-height: $button-badge-min-dimension;
    border-radius: 8px; // hard code as IE doesn't like: calc(#{$button-badge-min-dimension} / 2);
    position: absolute;
    padding: 0 3px;
    left: -6px;
    bottom: -1px;
    z-index: 1;
  }
}

.btn-add-card {
  @include text-small-caps;
  font-size: $font-size-base;
  border-radius: $border-radius;
  border: 0;
  width: 100%;
  padding: $space-sm;
  margin-bottom: $space-lg;
  flex-direction: row-reverse;
  justify-content: flex-end;

  [class^='icon-'] {
    font-size: $icon-dimension-large;
    display: inline-block;
    vertical-align: top;
    color: inherit;
  }

  span {
    padding-left: $space-sm;

    &:not(.visuallyhidden) ~ [class^='icon-'] {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
