// Icons

// Custom icon font & stylesheet generated via IcoMoon App: https://icomoon.io/app
// You can import *selection.json* back to the IcoMoon app using the *Import Icons* button (or via Main Menu → Manage Projects) to retrieve your icon selection.

.icon-download:before {
  content: '\e969';
}
.icon-marker-x:before {
  content: '\e95c';
}
.icon-marker-asterisk:before {
  content: '\e95d';
}
.icon-marker-plus:before {
  content: '\e95e';
}
.icon-marker-rhombus-solid:before {
  content: '\e95f';
}
.icon-marker-rhombus:before {
  content: '\e960';
}
.icon-marker-square-solid:before {
  content: '\e961';
}
.icon-marker-square:before {
  content: '\e962';
}
.icon-marker-diamond-solid:before {
  content: '\e963';
}
.icon-marker-diamond:before {
  content: '\e964';
}
.icon-marker-circle-solid:before {
  content: '\e965';
}
.icon-marker-circle:before {
  content: '\e966';
}
.icon-marker-triangle-solid:before {
  content: '\e967';
}
.icon-marker-triangle:before {
  content: '\e968';
}
.icon-clipboard-arrow-left:before {
  content: '\e95b';
}
.icon-marker-water-level-baseline:before {
  content: '\e95a';
}
.icon-marker-dam-levelling:before {
  content: '\e954';
}
.icon-marker-deformation:before {
  content: '\e955';
}
.icon-marker-deformation-redundant:before {
  content: '\e956';
}
.icon-marker-observation-well:before {
  content: '\e957';
}
.icon-marker-piezometer-tip:before {
  content: '\e958';
}
.icon-marker-water-level:before {
  content: '\e959';
}
.icon-menu::before {
  content: '\e953';
}
.icon-cross-hair::before {
  content: '\e952';
}
.icon-offline::before {
  content: '\e950';
}
.icon-online::before {
  content: '\e951';
}
.icon-envelope::before {
  content: '\e94a';
}
.icon-at::before {
  content: '\e94b';
}
.icon-pause::before {
  content: '\e94c';
}
.icon-camera::before {
  content: '\e94d';
}
.icon-not-a-number::before {
  content: '\e94e';
}
.icon-phone::before {
  content: '\e94f';
}
.icon-tick-outline::before {
  content: '\e949';
}
.icon-clipboard-tick::before {
  content: '\e948';
}
.icon-exchange::before {
  content: '\e947';
}
.icon-magnifing-glass-plus::before {
  content: '\e946';
}
.icon-mute::before {
  content: '\e92b';
}
.icon-formula::before {
  content: '\e941';
}
.icon-multimedia::before {
  content: '\e942';
}
.icon-parameter::before {
  content: '\e943';
}
.icon-route-march::before {
  content: '\e944';
}
.icon-sliders::before {
  content: '\e945';
}
.icon-sort::before {
  content: '\e93e';
}
.icon-sort-ascend::before {
  content: '\e93f';
}
.icon-sort-descend::before {
  content: '\e940';
}
.icon-resume::before {
  content: '\e93d';
}
.icon-switch::before {
  content: '\e93c';
}
.icon-house::before {
  content: '\e90e';
}
.icon-readings::before {
  content: '\e93b';
}
.icon-observation-point::before {
  content: '\e939';
}
.icon-view::before {
  content: '\e93a';
}
.icon-arrow-double-down::before {
  content: '\e936';
}
.icon-arrow-double-up::before {
  content: '\e937';
}
.icon-reorder::before {
  content: '\e938';
}
.icon-past::before {
  content: '\e935';
}
.icon-csv::before {
  content: '\e934';
}
.icon-minus-solid::before {
  content: '\e933';
}
.icon-plus::before {
  content: '\e92f';
}
.icon-star::before {
  content: '\e930';
}
.icon-tick::before {
  content: '\e931';
}
.icon-upload::before {
  content: '\e932';
}
.icon-area::before {
  content: '\e92c';
}
.icon-members::before {
  content: '\e92e';
}
.icon-data-loggers::before {
  content: '\e92a';
}
.icon-spanner::before {
  content: '\e92d';
}
.icon-pdf::before {
  content: '\e926';
}
.icon-png::before {
  content: '\e927';
}
.icon-format::before {
  content: '\e928';
}
.icon-filter::before {
  content: '\e929';
}
.icon-arrow-rounded-left::before {
  content: '\e924';
}
.icon-play::before {
  content: '\e925';
}
.icon-cross::before {
  content: '\e923';
}
.icon-audit-log::before {
  content: '\e91f';
}
.icon-comment::before {
  content: '\e920';
}
.icon-site::before {
  content: '\e921';
}
.icon-new-reading::before {
  content: '\e922';
}
.icon-arrow-double-left::before {
  content: '\e900';
}
.icon-arrow-double-right::before {
  content: '\e901';
}
.icon-arrow-down::before {
  content: '\e902';
}
.icon-arrow-left::before {
  content: '\e903';
}
.icon-arrow-right::before {
  content: '\e904';
}
.icon-arrow-up::before {
  content: '\e905';
}
.icon-bell::before {
  content: '\e906';
}
.icon-bell-solid::before {
  content: '\e96a';
}
.icon-calendar::before {
  content: '\e907';
}
.icon-circle::before {
  content: '\e908';
}
.icon-circle-info::before {
  content: '\e909';
}
.icon-circle-minus::before {
  content: '\e90a';
}
.icon-circle-plus::before {
  content: '\e90b';
}
.icon-circle-tick::before {
  content: '\e90c';
}
.icon-cog::before {
  content: '\e90d';
}
.icon-detach::before {
  content: '\e90f';
}
.icon-edit::before {
  content: '\e910';
}
.icon-exclamation-solid::before {
  content: '\e911';
}
.icon-export::before {
  content: '\e912';
}
.icon-info-solid::before {
  content: '\e913';
}
.icon-list::before {
  content: '\e914';
}
.icon-lock::before {
  content: '\e915';
}
.icon-log-in::before {
  content: '\e916';
}
.icon-log-out::before {
  content: '\e917';
}
.icon-plot::before {
  content: '\e918';
}
.icon-print::before {
  content: '\e919';
}
.icon-processing::before {
  content: '\e91a';
}
.icon-save::before {
  content: '\e91b';
}
.icon-tick-solid::before {
  content: '\e91c';
}
.icon-update::before {
  content: '\e91d';
}
.icon-user::before {
  content: '\e91e';
}
.icon-audio-file:before {
  content: '\e96b';
}
