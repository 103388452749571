#card-section-field-civil-features-table {
  h3,
  legend {
    width: auto;
  }

  .form-group-content {
    width: auto;
  }
}

.civil-feature-edit-item {
  width: 500px;
  padding: 5px;

  input {
    width: 290px !important;
  }

  button {
    width: 83px;
    text-align: center;
    float: right;
    left: -10px;

    span {
      width: 100%;
    }
  }
}

.civil-feature-retire-button {
  margin-left: 10px;
}

.civil-feature-add-item {
  width: 500px;
  margin-left: 35px;
  padding: 5px;

  input {
    width: 290px !important;
  }

  button {
    width: 83px;
    float: right;
    right: 103px;
  }
}
