// Utilities — mixins only

@mixin visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap; /* 1 */
}

// Text

@mixin text-small {
  font-size: $font-size-small;
  color: $font-color-secondary;
}

@mixin text-small-caps {
  font-family: $font-family;
  font-weight: bold;
  font-size: $font-size-small;
  color: $text-small-caps-color;
  text-transform: uppercase;
}

@mixin text-secondary-light {
  font-family: $font-family-secondary;
  font-weight: $font-weight-light;
}

@mixin text-secondary-bold {
  font-family: $font-family-header;
  font-weight: bold;
}

@mixin text-feature {
  font-family: $font-family-secondary;
  font-weight: $font-weight-light;
  font-size: 30px;
  line-height: $line-height-header;
  text-align: center;
}

// Text highlight
@mixin text-highlight {
  ::-moz-selection {
    background: $green-light;
    text-shadow: none;
  }

  ::selection {
    background: $green-light;
    text-shadow: none;
  }
}

// Text highlight - reverse
@mixin text-highlight-reverse {
  ::-moz-selection {
    background-color: $green-dark;
  }

  ::selection {
    background-color: $green-dark;
  }
}

// Button

@mixin button-reset {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  min-height: 0;

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }
}

// List

@mixin list-inline {
  list-style-type: none;
  padding-left: 0;

  li {
    display: inline-block;
  }
}

// Icon - use if inserting via css ::before or ::after

@mixin icon-insert {
  content: '';
  font-family: $icon-font;
  font-weight: normal;
}

@mixin icon-insert-nav-active {
  content: $icon-arrow-rounded-left;
  font-family: $icon-font;
  color: $background-color;
  font-size: 28px;
  line-height: 28px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 6px;
  right: 0;
  overflow: hidden;
}

// Icon actions
th.action-icons {
  > * {
    font-size: $font-size-base;
  }
}

// Functional block, used for filters & file upload - default grey
@mixin functional-block {
  padding: 15px $space-lg $space-lg;
  background-color: $functional-block-background;
  border-top-right-radius: 40px;
}

// Text underline border, used on page h1 & panels
@mixin text-underline-insert {
  content: '';
  display: inline-block;
  width: 100%;
  height: $space-xs;
  background-color: $border-color;
  position: absolute;
  bottom: 0;
  left: 0;
}

// Left border on card, generally used on nested cards
@mixin card-left-border {
  content: '';
  display: inline-block;
  width: 6px;
  height: calc(100% + 2px); // + border
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: $blue-mid;
  border-top-left-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

// Secondary card header, generally used in nested cards
@mixin card-header-secondary {
  @include text-small-caps;
  font-size: $font-size-base;
  line-height: $icon-dimension-large;
  vertical-align: top;

  [class^='icon-'] {
    font-size: $icon-dimension-large;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
  }
}

// Small active indicator arrow, used on collapsed nav
// Note: parent element must be positioned
@mixin active-indicator-arrow {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 6px 6px;
  border-color: transparent transparent $white transparent;
  position: absolute;
  bottom: 3px;
  right: 3px;
}

// Small (subtle) up arrow, used on react-select
// Note: parent element must be positioned
@mixin indicator-arrow-up {
  content: '';
  top: -4px;
  left: auto;
  right: 14px;
  border-color: transparent transparent $white transparent;
  border-width: 0 4px 4px 4px;
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

// Note: must @include indicator-arrow-up first, as a base
@mixin indicator-arrow-up-align-left {
  left: 14px;
  right: auto;
}

// Webkit-only scrollbar
// won't affect other browsers, but webkit will have a prettier time
@mixin scroll-horizonal {
  &::-webkit-scrollbar {
    height: 10px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $green-mid-dark;
  }
}

@mixin scroll-vertical {
  &::-webkit-scrollbar {
    width: 10px;
    background-color: $grey-light;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: $green-mid-dark;
  }
}

// Scatter multi-plot layout helper
@mixin multi-scatter-plot-item-count {
  @for $number from 2 through 4 {
    &:nth-last-child(#{$number}):first-child,
    &:nth-last-child(#{$number}):first-child ~ div {
      width: 50%;
    }
  }

  @for $number from 5 through 6 {
    &:nth-last-child(#{$number}):first-child,
    &:nth-last-child(#{$number}):first-child ~ div {
      width: 33.33%;
    }
  }

  @for $number from 7 through 8 {
    &:nth-last-child(#{$number}):first-child,
    &:nth-last-child(#{$number}):first-child ~ div {
      width: 25%;
    }
  }
}
