@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

#formula-history-content {
  .card::after {
    background-color: $green-mid;
  }

  .card-header h2 {
    @include card-header-secondary;
  }

  .card-row.highlight dd {
    color: $warning-color;
    flex-grow: 1;
    position: relative;
    z-index: 1;
    align-self: flex-start;

    &::before {
      content: '';
      display: inline-block;
      background-color: $warning-color-secondary;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      position: absolute;
      top: -4px;
      left: -4px;
      z-index: -1;

      @media #{$large-and-above} {
        border-top: 2px solid white;
      }
    }
  }
}
