@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.btn-link-text {
  color: $link-color;
  font-family: $font-family;
  font-size: $font-size-base;
  &:hover {
    text-decoration: underline;
  }
}
