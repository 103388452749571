@import 'src/basestyles/variables';

.back-button {
  border-radius: 0 $border-radius $border-radius 0;
  background-color: $blue-mid;
  border: 0;
  position: absolute;
  left: -$space-lg;

  &:hover {
    background-color: $blue-mid-dark;
  }

  [class^='icon-'] {
    color: $white;
  }
}
