@import 'src/basestyles/variables';

.manage-exclusions {
  .button-area {
    float: right;

    .edit-button {
      margin-right: $space-sm;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .main {
    display: flex;
    width: 100%;

    .title,
    .label {
      width: 20%;

      p {
        width: 80%;
        margin: 0;
        font-weight: bold;
      }
    }

    .label {
      color: $grey-mid-dark;
    }

    .exclusions {
      width: 60%;

      textarea {
        width: 50%;
      }
    }
  }
}
