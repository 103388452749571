@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// Layout
.page {
  display: flex;
  height: 100%;

  &.page-standard {
    align-items: stretch;
    min-height: 100%;
    background-color: $white;
  }

  .main-content-block {
    min-height: 100%;
    flex: 1;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    margin-left: $nav-width-expanded;
    max-width: calc(100% - #{$nav-width-expanded});
    transition: width $nav-collapse-duration ease;

    @media print {
      margin-left: 0 !important;
      display: block;
      max-width: none;
    }

    &.nav-expanding,
    &.nav-expanded {
      display: flex;
      margin-left: $nav-width-expanded;
      max-width: calc(100% - #{$nav-width-expanded});

      @media print {
        display: block;
        max-width: none;
      }
    }

    &.nav-collapsing,
    &.nav-collapsed {
      margin-left: $nav-width-collapsed;
      max-width: calc(100% - #{$nav-width-collapsed});

      @media print {
        max-width: none;
      }
    }

    &.nav-collapsing,
    &.nav-expanding {
      transition: margin-left $nav-collapse-duration;
    }
  }

  .main-content {
    display: flex;
    flex: 1 auto; // auto because ie
    align-items: stretch;

    @media print {
      display: block;
    }

    main {
      flex: 1;
      padding: $space-md $space-lg $space-xl;
      overflow: hidden;

      @media print {
        padding: 0;
        display: block;
      }

      > *:first-child {
        margin-top: 0;
      }
    }

    // additional content panel
    section {
      width: $panel-width;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
}

// Page header

.page-header {
  position: relative;
  padding-left: $space-lg;
  padding-right: $space-lg;
  display: flex;
  flex-direction: column;

  @media print {
    display: block;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 0;
    min-height: 0;
  }

  @media #{$medium-and-above} {
    flex-direction: row;
  }

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;

    @media print {
      display: none;
    }
  }

  &::before {
    background-color: $green-grey-light;
    background-image: url('../../../assets/images/header-contour.svg');
    background-size: 500px 100px;
    background-position: top right;
  }

  &::after {
    // RGBA of $green-grey-light
    background: linear-gradient(
      to right,
      rgba(224, 234, 234, 1) 0%,
      rgba(224, 234, 234, 1) 25%,
      rgba(224, 234, 234, 0.75) 55%,
      rgba(224, 234, 234, 0) 90%,
      rgba(224, 234, 234, 0) 100%
    );
  }

  > * {
    z-index: $z-index-header-content;
  }

  h1 {
    position: relative;
    margin-top: 14px;
    margin-right: auto;
    margin-bottom: 0;
    padding-bottom: 14px;
    min-height: 32px;

    @media print {
      font-size: $font-size-base;
      padding-bottom: 0;
      margin: 0 0 8px;
      min-height: 0px;
    }

    @media #{$medium-and-above} {
      margin-right: $space-xl;
    }
  }
}
