@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

div.DateInput-wrapper {
  display: flex;
  flex-wrap: wrap;

  input {
    text-align: center;
    width: 48px;
    padding-left: 2px;
    padding-right: 2px;

    // in card, column 2
    .card-row-col-2 & {
      @media #{$x-large-and-above} {
        width: 35px;
      }
    }

    // in advanced filters, or filter block
    .report-filter-modal &,
    .filter-block & {
      @media #{$medium-and-above} {
        width: 42px;
      }
    }
  }

  .DateInput-day,
  .DateInput-month {
    margin-right: 4px;
  }

  .DateInput-year {
    width: 56px;
    margin-right: $space-sm;

    &:last-child {
      margin-right: 0;
    }

    // in card, column 2
    .card-row-col-2 & {
      @media #{$x-large-and-above} {
        width: 42px;
      }
    }

    // in advanced filters, or filter block
    .report-filter-modal &,
    .filter-block & {
      @media #{$medium-and-above} {
        width: 50px;
      }
    }
  }

  // in panel, align HH:mm to right
  .DateInput-hour {
    .panel & {
      margin-left: auto;
    }
  }

  .Hour-colon {
    width: 4px;
    text-align: center;
    display: inline-block;
    line-height: $input-min-height;
  }
}
