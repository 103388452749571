@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.user-nav {
  @include text-highlight-reverse;
  display: flex;
  align-self: flex-start;
  align-items: center;
  background-color: $blue-mid-dark;
  border-bottom-left-radius: $border-radius-large;
  border-bottom-right-radius: $border-radius-large;
  margin-left: auto;
  overflow: hidden;
  flex-shrink: 0;

  @media print {
    display: none;
  }

  @media #{$medium-and-above} {
    order: 2;
    margin-bottom: auto;
  }

  ul {
    @include list-inline;
    margin: 0;
    flex-shrink: 0;

    li {
      vertical-align: top;
      margin-left: 0;

      &:first-of-type {
        margin-left: $space-sm;

        @media #{$medium-and-above} {
          margin-left: $space-md;
        }
      }
    }

    a,
    button {
      @include button-reset;
      padding: 8px 4px;

      [class^='icon-'] {
        color: #a8ceb7; // custom colour
        font-size: $icon-dimension-large;
        width: $icon-dimension-large;
        height: $icon-dimension-large;
        line-height: $icon-dimension-large;
      }

      &:hover {
        text-decoration: none;
        transition: all $transition-duration-base ease;

        [class^='icon-'] {
          color: $green-faint;
        }
      }

      &.log-out-button {
        background-color: $green-mid-dark;
        padding-left: $space-sm;
        padding-right: $space-sm;
        margin-left: 8px;
      }

      &.profile-button {
        .profile-button-text {
          color: $white;
          font-family: $font-family;
          font-size: 13px;
          padding: 0 $space-xs 0 0;
          line-height: $icon-dimension-base;
          display: none;

          @media #{$medium-and-above} {
            display: block;
          }
        }

        [class^='icon-'] {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
}
