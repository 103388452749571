@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// left sidebar wrapper
.navigation-block {
  @include text-highlight-reverse;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%; // need to state height for ie11
  width: $nav-width-expanded;
  flex-grow: 0;
  flex-shrink: 0;
  position: fixed;
  background: $blue-mid-dark;
  background: linear-gradient(
    to bottom,
    $blue-mid-dark 25%,
    $blue-mid 75%,
    $green-mid 100%
  );
  color: $white;
  z-index: $z-index-nav;
  transition: width $nav-collapse-duration ease;

  @media print {
    display: none;
  }

  &.nav-expanding,
  &.nav-expanded {
    width: $nav-width-expanded;
  }

  &.nav-collapsing,
  &.nav-collapsed {
    width: $nav-width-collapsed;
  }

  // header
  header {
    display: flex;
    align-items: center;
    padding: $space-lg $nav-block-padding-x;
    min-height: $space-xxl;

    img {
      width: 40px; // because ie11
      height: 14px; // because ie11
    }
  }

  &.nav-expanding header,
  &.nav-expanded header {
    padding-left: $nav-block-padding-x-large;
    padding-right: $nav-block-padding-x-large;

    img {
      width: $space-xxl; // because ie11
      height: 21px; // because ie11
    }
  }

  // collapse trigger
  #nav-toggle-control {
    @include button-reset;
    color: $white;
    font-family: $font-family;
    justify-content: center;
    flex-direction: row-reverse;
    padding: $space-xs $nav-block-padding-x-large;
    background-color: $blue-mid;
    margin-top: auto;
    min-height: 40px;

    span {
      padding: 0;
    }

    [class^='icon-'] {
      color: $green-light;
      font-size: $icon-dimension-large;
      margin-left: 0;
    }

    &:hover,
    &:focus {
      background-color: $blue-mid-dark;

      [class^='icon-'] {
        color: $green-faint;
      }
    }
  }

  &.nav-expanding #nav-toggle-control,
  &.nav-expanded #nav-toggle-control {
    justify-content: flex-end;
  }

  // footer
  .powered-by {
    padding: $space-lg 13px;
  }

  &.nav-expanding .powered-by,
  &.nav-expanded .powered-by {
    padding-left: $nav-block-padding-x-large;
    padding-right: $nav-block-padding-x-large;
  }
}

// navigation

.nav-menu-wrapper {
  overflow: auto;
  height: 100%;
  @include scroll-vertical;
}

.nav-menu {
  // nav global
  ul {
    list-style-type: none;
    padding-left: 0;
    border-top: 1px solid $nav-border-color;
    margin-top: 0;
    margin-bottom: 0;
  }

  a,
  .nav-category-header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    border-bottom: 1px solid $nav-border-color;
    padding: 8px $nav-block-padding-x;
    min-height: 40px;
    line-height: 24px;

    .nav-expanding &,
    .nav-expanded & {
      justify-content: flex-start;
    }

    &:hover,
    &:focus {
      text-decoration: none;
      background-color: #3e626a; // custom colour
      transition: all $transition-duration-base ease;
      cursor: pointer;
    }
  }

  .nav-current-page a,
  .nav-category-active .nav-category-header {
    transition: background-color $transition-duration-base ease;
    cursor: default;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }

  .nav-current-page a {
    position: relative;

    &::after {
      @include icon-insert-nav-active;
    }
  }

  .nav-category-active .nav-category-header {
    .nav-category-control-icon {
      display: none;
    }

    .nav-collapsing &,
    .nav-collapsed & {
      position: relative;

      &::after {
        @include icon-insert-nav-active;
      }
    }
  }

  // top level items
  > ul > .nav-item a,
  .nav-category-header {
    font-weight: bold;

    // icon
    .nav-item-icon,
    .nav-category-icon {
      color: $blue-light;
      font-size: $icon-dimension-large;
      line-height: $icon-dimension-large;

      .nav-expanding &,
      .nav-expanded & {
        padding-right: $space-sm;
      }
    }

    // toggle
    .nav-category-control-icon {
      padding-left: $space-md;
      color: $blue-light;
      margin-left: auto;

      .nav-collapsing &,
      .nav-collapsed & {
        @include visuallyhidden;
      }
    }
  }

  // second level items
  ul.nav-category-items {
    font-size: $font-size-small;
    font-weight: normal;
    border-top: 0;
    padding-left: calc(
      #{$nav-block-padding-x} + #{$icon-dimension-base} + #{$space-md}
    ); // left padding + icon + icon space

    .nav-item {
      &:last-of-type a {
        border-bottom: 0;
      }

      a {
        font-weight: normal;
        padding-left: 0;

        &:hover,
        &:focus {
          background-color: transparent;
          color: $blue-light;
        }
      }
    }

    .nav-current-page a,
    .nav-current-page a:hover,
    .nav-current-page a:focus {
      color: $white;
      font-weight: bold;
    }
  }

  .nav-category {
    border-bottom: 1px solid $nav-border-color;

    &.nav-category-collapsed {
      ul.nav-category-items {
        display: none;
      }

      // active sub menu always visible
      &.nav-category-active ul.nav-category-items {
        display: block;
      }
    }
  }

  // different behavior for collapsed .nav-menu
  &.sub-menu-open {
    .nav-collapsed &,
    .nav-collapsing & {
      .nav-current-page a::after,
      .nav-category-header::after {
        // hide "active" indicator when sub menu is open
        display: none;
      }
    }
  }

  .nav-collapsed &,
  .nav-collapsing & {
    > ul > .nav-item .nav-text,
    .nav-category-header .nav-text {
      @include visuallyhidden;
    }

    .nav-category {
      border-bottom: 0;

      .nav-category-header {
        position: relative;
        width: 100%;
        height: 40px;
        background-color: $blue-dark;

        &::before {
          @include active-indicator-arrow;
          border-bottom-color: $blue-light;
        }

        &:hover,
        &:focus {
          background-color: $blue-dark;
        }
      }

      &.nav-category-active {
        .nav-category-header {
          cursor: pointer;
        }
      }

      .nav-category-header-secondary {
        position: relative;
        display: flex;
        align-items: center;
        color: $white;
        font-size: $font-size-base;
        font-weight: bold;
        height: $space-xxl;
        padding: 8px 22px 8px $space-sm;
        border-bottom: 1px solid $blue-mid-dark;

        .close {
          top: $space-xs;

          [class^='icon-'] {
            color: $green-faint;
          }

          &:hover [class^='icon-'] {
            color: $green-light;
          }
        }
      }

      .nav-category-items-wrapper {
        overflow: auto;
        position: absolute;
        top: 0;
        left: $nav-width-collapsed;
        width: 180px;
        height: 100%;
        padding: 0 $space-sm;
        background-color: $blue-dark;
        box-shadow: $box-shadow-nav-flyout;
        z-index: $z-index-nav-collapsed-flyout;
        @include text-highlight;
        @include scroll-vertical;
      }

      ul.nav-category-items {
        padding-left: 0;

        .nav-item {
          a {
            color: $white;
            padding-left: 0;
            padding-right: 0;
            margin-left: $space-sm;
            margin-right: $space-sm;
            justify-content: flex-start;
            border-bottom: 0;

            &:hover,
            &:focus {
              background-color: transparent;
              color: $blue-light;
            }
          }

          &:last-child a {
            border-bottom: none;
          }

          &.nav-current-page a,
          &.nav-current-page a:hover,
          &.nav-current-page a:focus {
            color: $white;
            font-weight: bold;

            &:after {
              display: none;
            }
          }
        }
      }

      &.nav-category-collapsed {
        .nav-category-items-wrapper {
          display: none;
        }

        .nav-category-header {
          background-color: transparent;

          &:hover,
          &:focus {
            background-color: #3e626a; // custom colour
          }
        }
      }
    }
  }
}
