@import 'src/basestyles/variables';

.disabled-date-filter-wrapper {
  select {
    color: $font-color-disabled;
    background: $grey-light;
    border-color: $grey-mid;
  }
  input {
    color: $font-color-disabled;
    background: $grey-light;
    border-color: $grey-mid;
  }
}

.date-filter-wrapper,
.disabled-date-filter-wrapper {
  .form-date-range {
    select {
      float: left;
      margin-right: 10px;
    }
  }
}
