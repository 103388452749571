// Action icons

.action-icons {
  white-space: nowrap;

  table & {
    width: 1px; // combined with white-space:no-wrap, table column is only as wide as its content
  }

  @media print {
    display: none;
  }

  > * {
    color: $icon-color;
    font-size: $icon-dimension-base;
    display: inline-block;
    line-height: $icon-dimension-base;
    vertical-align: top;
    margin-right: 8px;

    &:hover,
    &:focus {
      text-decoration: none;

      [class^='icon-'] {
        color: $link-color-hover;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
