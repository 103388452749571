@import 'src/basestyles/variables';

#screen-plotset-main {
  padding: $space-sm $space-lg;
  display: flex;
  flex-direction: column;
}

.plot-set-header {
  background-color: $green-faint;
  border-radius: $border-radius;
  padding: $space-xs $space-sm;
  margin-bottom: $space-md;

  @media #{$medium-and-above} {
    display: flex;
    align-items: center;
  }

  h2 {
    margin: 0 $space-md 0 0;
  }
}

.plot-set-pager {
  display: flex;
  align-items: center;
  margin-top: $space-xs;
  margin-bottom: 0;

  @media #{$medium-and-above} {
    margin-top: 0;
    margin-left: auto;
  }

  button,
  .btn {
    margin-bottom: 0;

    @media #{$medium-and-above} {
      border-radius: 50%;
    }

    [class^='icon-'] {
      color: $link-color;

      @media #{$medium-and-above} {
        font-size: $icon-dimension-large;
      }
    }

    .icon-arrow-left {
      padding-right: 3px;
    }

    .icon-arrow-right {
      padding-left: 3px;
    }

    &:hover,
    &:focus {
      [class^='icon-'] {
        color: $link-color-hover;
      }
    }
  }

  .react-select {
    flex-grow: 2;

    @media #{$medium-and-above} {
      width: 200px;
      font-size: 18px;
      line-height: 1em;
    }

    .react-select__control {
      min-height: 0;
    }

    .react-select__value-container {
      padding-top: 3px;
      padding-bottom: 3px;

      @media #{$medium-and-above} {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .react-select__dropdown-indicator {
      margin-top: 6px;
    }
  }
}
