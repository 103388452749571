@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// Card

.card {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-bottom: $card-margin-bottom;

  &.card-active {
    background-color: $form-background-color;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  }

  .card-header {
    padding: $card-padding-header-y $card-padding;
    border-bottom: 1px solid $border-color;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: ($font-size-base * 1.5); // match h2
      margin: $space-xs $space-md $space-xs 0;
    }

    .action-block {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .card-content {
    padding: $card-padding;

    fieldset {
      margin-top: 0;
      margin-bottom: 0;

      .card-row {
        margin-bottom: $space-sm;

        &:last-of-type {
          margin-bottom: 0;

          .form-group {
            @media #{$x-large-and-above} {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .card-section {
    margin-top: 0;
    margin-left: -#{$card-padding};
    margin-right: -#{$card-padding};
    padding: $card-padding;
    border-bottom: 1px solid $border-color;

    @media print {
      page-break-inside: avoid; // avoid breaking section onto two pages
    }

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    // nested card sections
    .card-section,
    &.nested {
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      > legend {
        position: static;
        width: 100%;

        @media #{$x-large-and-above} {
          width: $card-col-1-width-xl;
          padding-right: $space-xl;
        }
      }
    }
  }

  .card-form {
    min-height: $space-xxl; // min height while values are loading

    @media #{$medium-and-above} {
      position: relative;
      padding-left: calc(
        #{$card-form-section-header-width-md} + #{$card-padding}
      );
    }

    &:first-of-type {
      padding-top: 0;
      min-height: 40px; // min height while values are loading

      h3,
      legend {
        @media #{$medium-and-above} {
          margin-top: 0;
        }
      }

      legend {
        @media #{$x-large-and-above} {
          margin-top: 7px;
        }
      }
    }

    &:last-of-type {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
      min-height: 40px; // min height while values are loading
    }

    &:first-of-type:last-of-type {
      min-height: $space-lg; // min height while values are loading
    }

    h3,
    legend {
      font-size: $font-size-base;
      color: $card-form-section-header-color;
      margin-top: 0;

      @media #{$medium-and-above} {
        position: absolute;
        left: $card-padding;
        top: 0;
        width: $card-form-section-header-width-md;
        padding-right: $space-md;
        margin-top: $card-padding;
      }
    }

    .form-group {
      @media #{$x-large-and-above} {
        display: flex;
        margin-top: 0;
      }

      .non-editable-value {
        @media #{$x-large-and-above} {
          line-height: $input-min-height;
        }

        + .non-editable-value {
          @media #{$x-large-and-above} {
            line-height: $line-height;
          }
        }
      }
    }

    dl {
      margin-top: 0;
      margin-bottom: 0;

      .card-row:last-of-type {
        dt {
          @media #{$x-large-and-above} {
            margin-bottom: 0;
          }
        }

        dd {
          margin-bottom: 0;
        }
      }
    }

    dt,
    label {
      @media #{$x-large-and-above} {
        margin-bottom: $space-sm;
      }

      &::after {
        content: ':';
      }
    }

    legend {
      @media #{$x-large-and-above} {
        margin-top: 27px;
      }
    }

    label {
      @media #{$x-large-and-above} {
        padding-top: 7px;
        margin-bottom: 0;
      }
    }

    dd {
      min-height: $space-lg; // keeps height if no value exists
      margin-bottom: $space-sm;

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    // exclude date and time related inputs
    input:not([class^='DateInput-']),
    .input-unit-wrapper {
      width: 100%;
    }

    .radio-group-wrapper {
      margin-top: 0;
    }

    .radio-group,
    .check-group {
      input {
        width: auto;
      }

      label {
        position: static;
        width: 100%;

        &::after {
          content: '';
        }
      }
    }

    .error {
      margin-top: $space-xs;
    }

    .text-with-linebreaks {
      display: inline-block;
    }

    .non-editable-value {
      margin-top: 0;
      margin-bottom: 0;
    }

    // align multi-line label with input
    label .label-multi-line {
      @media #{$x-large-and-above} {
        position: relative;
        top: -7px;
      }
    }

    // for additional Cancel/Save buttons at bottom of card
    > .action-block {
      margin-top: 0;
      margin-bottom: 0;
      text-align: right;
    }
  }

  .card-row {
    clear: both;

    @media #{$x-large-and-above} {
      display: flex;
    }

    // renders text under the input area
    &.card-row-text-offset .form-group {
      @media #{$x-large-and-above} {
        padding-left: $card-col-1-label-width-xl;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &.card-row-file-upload {
      .form-group {
        padding-right: 96px;
        position: relative;
      }

      .form-group-content {
        line-height: $input-min-height;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      button {
        position: absolute;
        top: 25px; // label height
        right: 0; // hard coded for now

        @media #{$x-large-and-above} {
          top: 0;
        }
      }
    }
  }

  fieldset {
    .card-row:first-of-type {
      .card-row-col-1:first-of-type {
        .form-group {
          margin-top: 0;
        }
      }
    }
  }

  .card-row-col-1 {
    @media #{$large-and-above} {
      width: $card-col-1-width-lg;
    }

    @media #{$x-large-and-above} {
      display: flex;
      width: $card-col-1-width-xl;
      padding-right: $space-xl;
      flex-shrink: 0;
      margin-top: 0;
    }

    dt,
    label {
      @media #{$medium-and-above} {
        margin-top: 0;
      }

      @media #{$x-large-and-above} {
        width: $card-col-1-label-width-xl;
        padding-right: $space-md;
        flex-shrink: 0;
      }
    }

    dd,
    .form-group-content {
      @media #{$x-large-and-above} {
        width: $card-col-1-content-width-xl;
      }
    }
  }

  // only used for date fields
  // additional styling required if col-3 is used
  .card-row-col-2,
  .card-row-col-3 {
    border-left: 1px solid $border-color;
    padding-left: 12px;

    @media #{$large-and-above} {
      width: $card-col-2-width-lg;
    }

    @media #{$x-large-and-above} {
      width: $card-col-2-width-xl;
      position: relative;
      display: flex;
      border-left: 0;
      padding-left: 0;
    }

    .form-group {
      padding-right: 96px; // Fits button with text 'Edit' or 'Delete'
      position: relative;
    }

    dt,
    label {
      @media #{$x-large-and-above} {
        width: $card-col-2-label-width-xl;
        flex-shrink: 0;
        padding-right: $space-md;
        margin-top: 0;
      }
    }

    button:not(.input-unit) {
      position: absolute;
      top: 25px; // label height
      right: 0; // hard coded for now

      @media #{$x-large-and-above} {
        top: 0;
      }
    }

    &.card-section-nested-edit-button {
      button:not(.input-unit) {
        position: static;
      }
    }
  }

  .card-footer {
    padding-left: $card-padding;
    padding-right: $card-padding;
    border-top: 1px solid $border-color;
    background-color: $green-faint;
    border-bottom-left-radius: calc(#{$border-radius} - 1px);
    border-bottom-right-radius: calc(#{$border-radius} - 1px);

    // card nested in footer
    .card {
      background-color: $white;
      position: relative;
      margin-top: $space-lg;

      &::after {
        @include card-left-border;
      }

      .card-header,
      .card-content,
      .card-footer {
        padding-left: calc(#{$card-padding-header-y} + 6px);
      }
    }
  }
}
