@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.edit-raw-reading {
  .raw-reading-value {
    width: 79%;
  }

  .checkbox-toggle-wrapper {
    float: right;
  }
}
