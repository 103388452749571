#card-section-field-obs-point-performance-indicators-table {
  h3,
  legend {
    width: auto;
  }

  .form-group-content {
    width: auto;
  }
}

.obs-point-performance-indicator-edit-item {
  width: 460px;
  padding: 5px;

  input {
    width: 290px !important;
  }

  button {
    float: right;
    left: -10px;
  }
}

.obs-point-performance-indicator-add-item {
  width: 460px;
  margin-left: 35px;
  padding: 5px;

  input {
    width: 290px !important;
  }

  button {
    float: right;
    left: -7px;
  }
}

#obs-point-performance-indicator-add-select-outer {
  width: 350px;
}
