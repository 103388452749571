@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.filter-block {
  margin-top: 0;
  margin-bottom: $space-md;

  @media #{$large-and-above} {
    min-width: 0;
    flex-grow: 1;
  }

  > legend {
    display: none;
  }

  .columns-even {
    > * {
      margin-top: 0;
      margin-bottom: $space-md;

      @media #{$large-and-above} {
        margin-bottom: 0;
        margin-left: $space-lg;
        max-width: 340px;
      }
    }

    > .form-group:first-of-type {
      @media #{$large-and-above} {
        margin-left: 0;
      }
    }
  }

  .form-group {
    @include functional-block;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    padding-right: 50px;

    &.filter-has-value {
      background: $functional-block-background-active;
    }

    > label {
      @include text-secondary-bold;
      color: $blue-dark;
      margin-bottom: $space-sm;
      margin-right: $space-md;

      &::after {
        content: '';
      }
    }

    > *:last-child {
      flex-basis: 100%;
    }
  }

  .input-unit-wrapper {
    display: block;

    input {
      width: 100%;
    }
  }

  .check-group-wrapper label {
    padding-top: 0;
    padding-right: 0;
  }
}

// Active filters
@media print {
  .ssr-active-filters-block {
    font-size: $font-size-small;
    margin-bottom: $space-xs;

    p {
      display: inline-block;
      margin: 0 $space-xs $space-xs 0;
    }

    ul {
      @include list-inline;
      display: inline;
    }

    .ssr-active-filter {
      border: 1px solid $border-color;
      border-radius: $border-radius-small;
      padding: 1px $space-xs;
      margin-right: $space-xs;
      margin-bottom: $space-xs;

      strong {
        font-family: $font-family-secondary;
      }
    }
  }
}
