// Modal
@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// Modal

.ReactModalPortal {
  position: relative;
  z-index: 2;
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(51, 86, 94, 0.5) !important; // override inline styles
  display: flex;
  align-items: stretch;
  justify-content: flex-end;

  &.modal-centred {
    justify-content: center;
    align-items: center;
  }
}

.modal {
  background-color: $white;
  box-shadow: $box-shadow;
  width: $modal-width;
  margin: auto $space-md;
  border-top: 5px solid $blue-dark;

  &.modal-danger {
    border-top-color: $danger-color;
  }

  .modal-inner {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    padding: $modal-padding;
    @include scroll-vertical;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  .modal-header {
    position: relative;
    padding-right: $space-xl; // space for close icon
    margin-bottom: $space-md;

    h2,
    h3 {
      font-size: ($font-size-base * 1.285); // match h3
      color: $blue-dark;
      display: inline-block;
      position: relative;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.modal-danger .modal-header {
    h2,
    h3 {
      color: $danger-color;
    }
  }

  legend {
    @include text-small-caps;
  }

  // second level +, legend styled like a label
  fieldset fieldset legend {
    font-family: $font-family-secondary;
    font-size: $font-size-base;
    font-weight: normal;
    color: $font-color-secondary;
    text-transform: none;
    margin-bottom: $space-xs;
  }

  input {
    min-width: 0;
    width: 100%;
  }

  .action-block {
    margin-bottom: 0;
  }
}

.modal.panel {
  background-color: $blue-faint;
  padding: 0;
  width: $panel-width;
  min-width: $panel-width;
  margin: 0;
  border-top: 0;
  margin-left: $space-xl;

  &.panel-large {
    transition: width $transition-duration-base ease;

    @media #{$medium-and-above} {
      width: $panel-width-large;
    }
  }

  .modal-inner {
    > *:last-child {
      margin-bottom: 50px;
    }
  }

  .modal-header {
    border-bottom: 1px solid $blue-dark;
    margin-bottom: $space-xl;

    h2,
    h3 {
      color: $font-color;
      padding-bottom: $space-md;

      &::after {
        @include text-underline-insert;
        background-color: $blue-dark;
      }
    }
  }

  .action-block {
    margin-bottom: $space-md;
  }
}
