@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.plot-popup-wrapper {
  position: absolute;
  max-width: 500px;
}

.plot-popup-spatial {
  background-color: $white;
  border-radius: $border-radius;
  padding: 12px;
  border: 1px solid $border-color;
}

.popup-header {
  display: flex;
  justify-content: space-between;
  margin-right: 12px; // space for close icon
  margin-bottom: $space-sm;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: $space-md;
  }

  a {
    color: $link-color;
    line-height: 20px;

    &:hover,
    &:focus {
      text-decoration: none;
      color: $link-color-hover;
    }

    [class^='icon-'] {
      font-size: $icon-dimension-large;
    }
  }

  // match google map close as we can't modify it easily
  .close {
    top: -1px;
    right: -3px;

    [class^='icon-'] {
      transition: opacity $transition-duration-base ease;
      color: $font-color;
    }

    &:hover [class^='icon-'] {
      color: #000;
    }
  }
}

.popup-content {
  h4 {
    margin-top: $space-sm;
    margin-bottom: 2px;
    font-family: $font-family;
    font-size: $font-size-base;
  }

  p {
    font-family: $font-family;
    font-size: $font-size-base;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: $space-xs;
  }

  .alert {
    margin-top: $space-md;
    margin-bottom: 0;
    margin-right: $space-md; // space for close icon
  }
}

// Timeseries specific
.plot-popup-timeseries {
  border-radius: $border-radius;
  display: flex;
  background-color: $blue-dark;
  color: $white;
  min-width: 230px; // reserve area when loading
  min-height: 100px; // reserve area when loading
  overflow: hidden;
  justify-content: space-between;

  .close {
    top: $space-xs;
    right: calc(#{$space-xs} + 3px);

    [class^='icon-'] {
      color: $white;
      transition: opacity $transition-duration-base ease;
    }

    &:hover [class^='icon-'] {
      opacity: 0.6;
    }
  }

  .plot-popup-timeseries-info {
    padding: $popup-padding;

    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .plot-popup-timeseries-action {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 40px;
    padding-top: $space-md;
    padding-bottom: $space-xs;

    .btn,
    button {
      @include button-reset;
      display: inline-block;
      padding: 0;
      margin-top: $space-xs;

      &.btn-panel-active::after {
        display: none;
      }

      &.btn-panel-active .icon-comment,
      &:hover [class^='icon-'] {
        opacity: 0.6;
      }

      [class^='icon-'] {
        font-size: $icon-dimension-large;
        color: $white;
        transition: opacity $transition-duration-base ease;
      }
    }

    .button-badge {
      background-color: $white;
      color: $font-color;
    }
  }
}
