@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

#screen-storedplot-main {
  display: flex;
  flex-direction: column;
}

.plot-item-detail {
  margin-bottom: $space-xs;

  .plot-item-header {
    font-family: $font-family;
    font-size: ($font-size-base * 1.142); // ~16px

    span {
      font-weight: normal;
    }
  }

  .plot-item-header,
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

// Scatter specific
.page-multi-plot-scatter {
  display: flex;
  flex-wrap: wrap;

  .plot-item {
    border: 1px solid $grey-mid;
    padding: $space-sm $space-md;
    width: 100%;
    margin-bottom: $space-md;

    @media #{$medium-and-above} {
      @include multi-scatter-plot-item-count;
    }

    &:only-of-type {
      border: 0;
      padding: 0;
    }
  }
}

// Timeseries specific
.page-multi-plot-timeseries {
  flex: 1;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    flex-direction: column;
  }

  .plot-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .plot-area {
      flex: 1;
    }

    .alert {
      margin-top: $space-md;
    }
  }
}

// PDF specific styles
.pdf-plot-page {
  h1 {
    display: block;
    width: 100%;

    .stored-plot-title {
      display: inline-block;
    }
    .stored-plot-name {
      display: inline;
      position: absolute;
      right: 0;
    }
  }
  .plot-item-scatter {
    display: inline-block;
    vertical-align: top;
    padding-right: $space-xs;
    padding-left: 0;
    margin-bottom: 0;
  }

  .plot-item-detail {
    margin-bottom: 0;
    padding-left: $space-md;

    .plot-item-header {
      font-size: $font-size-small;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $space-lg; /*space for side-by-side plot detail*/
    }
  }

  .plot-legend {
    padding-left: $space-md;
  }

  .spatial-plot-area-container {
    overflow: hidden;
  }

  // data table
  .data-table {
    max-width: 320px;

    th,
    td {
      font-size: 11px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  .data-table-wander {
    max-width: 250px;
  }

  // spatial footer
  .spatial-plot-border {
    border-radius: $border-radius-pdf;
  }

  .spatial-footer-container {
    padding-top: $space-xs;
    padding-bottom: $space-xs;

    .footer-box {
      float: right;
      height: 100%;
      padding: $space-sm $space-md;
      margin-left: $space-xs;
      border-radius: $border-radius-pdf;
      min-width: 150px;

      h1 {
        font-family: $font-family;
        font-weight: normal;
        font-size: 26px;
        margin-top: 11px;
        margin-bottom: 0;
        text-align: center;
      }

      p {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1.25em;
        text-transform: uppercase;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .footer-box-info {
      width: 380px;
      padding-top: $space-xs - 3px;
      padding-bottom: $space-xs;
      font-size: 14px;
      line-height: 1.2em;
    }

    .footer-box-dsi {
      padding-left: $space-xs;
      padding-right: $space-xs;

      img {
        width: 140px;
        height: 38px;
        margin-top: 8px;
      }
    }

    .footer-box-client {
      text-align: center;

      img {
        height: 100%;
      }
    }
  }
}
