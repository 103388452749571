.cumulative-settings-plot-time-period {
  .form-group {
    margin-top: 0;
    display: inline-block;
  }

  .start-year {
    margin-right: 10px;
  }

  input {
    width: 145px;
  }
}
