@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.file-upload {
  @include functional-block;
  width: $sidebar-width;
  margin-bottom: $space-md;

  @media #{$large-and-above} {
    margin-top: 0;
  }

  &.file-upload-has-permission {
    background-color: $functional-block-background-active;

    h3::after,
    .file-upload-message {
      color: $green-mid-dark;
    }
  }

  h3 {
    position: relative;
    margin-top: 0;
    color: $blue-dark;
    font-size: $font-size-base;
    line-height: $line-height;
    padding-bottom: 120px;

    &::after {
      @include icon-insert;
      content: $icon-upload;
      font-size: 140px;
      line-height: 0.65em; // cater for wide upload icon
      position: absolute;
      bottom: 0;
      left: calc(50% - 70px);
      color: $grey-mid;
    }
  }

  .file-upload-message {
    font-size: $font-size-large;
    font-style: italic;
    text-align: center;
    margin-bottom: $space-xl;
    color: $font-color-secondary;
  }

  input[type='file'] {
    border: 0;
    padding: 0;
    margin-top: $space-md;
    margin-bottom: $space-md;
    width: 100%;
  }
}
