@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// Pagination

.pagination {
  @include list-inline;
  display: inline-block;
  margin-top: 4px;

  @media print {
    display: none;
  }

  li {
    vertical-align: top;
    margin-right: $space-sm;
    margin-bottom: $space-xs;

    a {
      font-size: $font-size-small;
      display: inline-block;
      line-height: $pagination-line-height;
      padding: $pagination-padding 0;
      text-align: center;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    &.active a,
    &.active a:hover {
      color: $font-color;
      border-width: 2px;
      border-color: $green-mid-dark;
      line-height: calc(#{$pagination-line-height} - 2px); // minus extra border
      background-color: transparent;
      cursor: default;
    }
  }

  // numbers and ellipses
  .btn-numbered-page a,
  .btn-prev-more a,
  .btn-next-more a {
    border-radius: $pagination-border-radius;
    border: $pagination-border-width solid $green-faint;
    min-width: $pagination-dimension;
    height: $pagination-dimension;
    padding: $pagination-padding;

    &:hover {
      background-color: $green-faint;
    }
  }

  // first/last, prev/next
  .btn-first-page,
  .btn-prev-page,
  .btn-next-page,
  .btn-last-page {
    a {
      position: relative;

      &::after {
        @include icon-insert;
        position: absolute;
        top: 6px;
      }

      &:hover {
        background-color: transparent;
        text-decoration: underline;
      }
    }

    &.disabled a {
      color: $grey-mid;

      &:hover {
        cursor: default;
        text-decoration: none;
      }
    }
  }

  .btn-first-page a {
    padding-left: $space-md;

    &::after {
      content: $icon-arrow-double-left;
      left: 0;
    }
  }

  .btn-prev-page a {
    padding-left: $space-md;

    &::after {
      content: $icon-arrow-left;
      left: 0;
    }
  }

  .btn-next-page a {
    padding-right: $space-md;

    &::after {
      content: $icon-arrow-right;
      right: 0;
    }
  }

  .btn-last-page a {
    padding-right: $space-md;

    &::after {
      content: $icon-arrow-double-right;
      right: 0;
    }
  }
}
