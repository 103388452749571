// Crosshair
@import 'src/basestyles/variables';

.rv-crosshair {
  .rv-crosshair__line.crosshair-line {
    background: $blue-dark;
  }

  .reticle {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 23px;
    height: 23px;
    // Need to overrwrite this because react-vis disable pointer-events
    // We want to be able to click to the reticle to show the reading details
    pointer-events: auto;

    &:hover {
      cursor: pointer;
    }
  }

  .reticle-inner {
    width: 23px;
    height: 23px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      border-radius: 50%;
    }

    &::before {
      background-color: currentColor;
      opacity: 0.25;
      width: 100%;
      height: 100%;
    }

    &::after {
      background-color: $white;
      border: 3px solid currentColor;
      width: 13px;
      height: 13px;
      top: $space-xs;
      left: $space-xs;
    }
  }
}
