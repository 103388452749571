@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.checksheet-icon-error {
  color: $danger-color !important;
}

.checksheet-icon-alarm {
  color: $warning-color !important;
}

.table-cell-checksheet-feature-name {
  // web view only
  width: 30%;
}

.table-cell-checksheet-comment {
  // web view only
  width: 35%;
}

.edit-comment-button {
  margin-left: 10px;
}
