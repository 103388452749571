@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

// Loading - in table
td .alert.loading {
  padding: 0;
  background-color: transparent;
  border: none;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  min-width: 130px;

  @keyframes loading-spin-background {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes loading-spin-foreground {
    0% {
      transform: rotate(-45deg);
    }
    100% {
      transform: rotate(315deg);
    }
  }

  &::before,
  &::after {
    content: '';
    display: block;
    height: $loading-circle-width;
    width: $loading-circle-width;
    margin: 0 auto $space-lg;
    border-radius: 50%;
  }

  // green part of spinner. note: have used this method so we can put an inset shadow on the element — edges of a dark shape can be seen under a light one, even if they are the same size
  &::before {
    background: linear-gradient(315deg, $green-light 50%, $green-mid 50%);
    box-shadow: inset 0 0 0 1px $table-empty-background-color; // smooth edges
    animation: loading-spin-background 2.2s linear infinite;
  }

  // grey part of spinner
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border: $loading-circle-border-width solid $white;
    border-left-color: transparent;
    animation: loading-spin-foreground 2.2s linear infinite;
  }

  .alert-header {
    @include text-feature;
    display: block;

    // inner circle of loading spinner (background colour)
    &::before {
      content: '';
      display: block;
      position: absolute;
      height: calc(
        #{$loading-circle-width} - #{$loading-circle-border-width} * 2
      );
      width: calc(
        #{$loading-circle-width} - #{$loading-circle-border-width} * 2
      );
      margin: 0 auto $space-lg;
      border-radius: 50%;
      top: $loading-circle-border-width;
      left: 0;
      right: 0;
      background-color: $table-empty-background-color;
      box-shadow: 0 0 0 1px $table-empty-background-color; // smooth edges
      z-index: 1;
    }

    // position ellipsis outside centre alignment, for visual balance
    &::after {
      content: '…';
      position: absolute;
    }
  }
}
