@import 'src/basestyles/variables';

.raw-reading-value {
  dl {
    position: relative;
    float: right;
    margin: 0;
    padding-left: calc(#{$space-md} + #{$space-xl});

    dt {
      margin: 0;
      position: absolute;
      left: 0;
      width: $space-xl;
      text-align: center;
      background-color: $grey-faint;
      font-size: $font-size-small;
      border: 1px solid $border-color;
      border-radius: $border-radius;
      line-height: 18px;
    }

    dd {
      margin-bottom: $space-xs;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
