@import 'src/basestyles/variables';

.inspection-report-media {
  display: inline-block;
  vertical-align: top;
}

.inspection-report-media-label {
  margin-left: 4px;
}

.performance-indicator-response-icon-observed {
  color: $yellow;
}

.performance-indicator-response-icon-issue {
  color: $danger-color;
}

.inspection-report-media-export {
  cursor: default;
  line-height: normal !important;
  margin-top: -4px;
  label {
    display: inline-block;
    font-size: $font-size-small !important;
    font-family: $font-family !important;
    margin-bottom: 0px;
  }
  label::after {
    content: none;
  }
  input[type='checkbox'] {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 0px;
    width: 12px;
  }
}

.inspection-report-media-requires-caption-icon {
  margin-left: 5px !important;
  white-space: nowrap;
  color: $danger-color !important;
  font-size: $font-size-small !important;
  height: auto !important;
  line-height: normal !important;
  span {
    margin-left: 5px;
    font-family: $font-family !important;
  }
}

td.inspection-report-performance-indicators {
  min-width: 500px;
}

td.inspection-report-inspection-comment {
  min-width: 200px;
}

td.preview-media {
  min-width: 300px;
}
