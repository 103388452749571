@import 'src/basestyles/variables';

// target first 'Formula' group only
.formula-overview {
  .card-row:first-of-type {
    .card-row-col-1:first-of-type {
      position: relative;
    }
  }

  .form-group {
    .change-formula-value {
      display: inline-block;
      padding-top: 7px;
      padding-bottom: 7px;
      padding-right: $space-md;

      @media #{$medium-and-above} {
        padding-right: 170px; // hard coded for now
      }

      @media #{$x-large-and-above} {
        padding-right: 0;
      }
    }

    .change-formula-button {
      @media #{$medium-and-above} {
        position: absolute;
        right: 0;
      }

      @media #{$x-large-and-above} {
        right: -152px; // hard coded for now
      }
    }
  }
}
