@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.comment-no-results {
  @include text-feature;
}

.comment-list {
  list-style: none;
  padding-left: 0;

  li {
    background-color: $white;
    border-radius: $border-radius;
    border-left: 6px solid $border-color;
    margin-bottom: $space-sm;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border-color;
    padding: $space-md;

    .comment-info {
      color: $text-small-caps-color;
      display: flex;
      align-items: center;
      min-width: 160px;

      [class^='icon-'] {
        font-size: $icon-dimension-base;
        margin-right: $space-xs;
      }

      .comment-date {
        color: $text-small-caps-color;
        font-weight: bold;
        margin-right: $space-md;
      }
    }

    .comment-author {
      @include text-secondary-light;
      text-align: right;
    }
  }

  .comment-text {
    padding: $space-md;
  }
}
