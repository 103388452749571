@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

table.FilesListTable tr {
  .table-cell-status::before {
    @include icon-insert;
    margin-right: $space-xs;
  }

  &.status-parse_error,
  &.status-processing_error {
    .table-cell-status::before {
      content: $icon-exclamation-solid;
      color: $danger-color;
    }
  }

  &.status-processed {
    .table-cell-status::before {
      content: $icon-tick-solid;
      color: $success-color;
    }
  }

  &.status-uploaded {
    .table-cell-status::before {
      content: $icon-upload;
      color: $info-color;
    }
  }

  &.status-processing {
    .table-cell-status::before {
      content: $icon-processing;
      color: $yellow;
    }
  }

  td.action-icons {
    text-align: right;
  }
}
