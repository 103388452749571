// Alerts
@import 'src/basestyles/variables';
@import 'src/basestyles/utilities';

.alert {
  padding: $space-md;
  background-color: rgba(255, 255, 255, 0.75);
  border: 1px solid $border-color;
  border-radius: $border-radius;
  margin-top: $space-xl;
  margin-bottom: $space-xl;
  clear: both;

  &.alert-success,
  &.alert-danger,
  &.alert-warning,
  &.alert-info {
    padding-left: 40px;
    position: relative;

    .alert-header::before {
      @include icon-insert;
      display: inline-block;
      margin-right: $space-xs;
      position: absolute;
      top: $space-md;
      left: $space-md;
    }
  }

  &.alert-condensed {
    padding-top: $space-sm;
    padding-bottom: $space-sm;
    margin-top: 0;
    margin-bottom: $space-sm;

    .alert-header::before {
      top: $space-sm;
    }

    .alert-content {
      margin-top: $space-xs;
    }
  }

  &.alert-success {
    border-color: $success-color;

    .alert-header {
      color: $success-color;

      &::before {
        content: $icon-tick-solid;
      }
    }
  }

  &.alert-danger {
    border-color: $danger-color;

    .alert-header {
      color: $danger-color;

      &::before {
        content: $icon-exclamation-solid;
      }
    }
  }

  &.alert-warning {
    border-color: $warning-color;

    .alert-header {
      color: $warning-color;

      &::before {
        content: $icon-exclamation-solid;
      }
    }
  }

  &.alert-info {
    border-color: $info-color;

    .alert-header {
      color: $info-color;

      &::before {
        content: $icon-info-solid;
      }
    }
  }

  &.alert-file-input {
    word-break: break-word; // wrap long file name with no spaces
  }

  .alert-header {
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-family-header;
    font-weight: bold;
    font-size: $font-size-large;
    display: inline-flex;
    align-items: flex-start;
    word-break: break-word;

    [class^='icon-'] {
      margin-right: $space-xs;
    }
  }

  .alert-content {
    margin-top: $space-md;

    dl {
      margin-top: 0;
      margin-bottom: 0;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }
}
