@import 'src/basestyles/variables';

.filtered-table-container {
  caption-side: bottom;
  display: block;
  position: relative;

  // pagination loading, opacity on table when navigating
  &[data-is-loading='true'] {
    tbody td:not(.td-no-results) {
      opacity: 0.5;
    }
  }
}

.filtered-table-result-count {
  p {
    font-family: $font-family-secondary;
    font-size: ($font-size-base * 1.5); // same as h2 - 21px
    font-weight: bold;
    line-height: $line-height-header;
    margin-top: 4px;
    margin-bottom: $space-xs;

    @media #{$medium-and-above} {
      display: inline-block;
    }
  }
}
