.inline-text-edit-content {
  white-space: pre-line;
}

.inline-text-edit-button {
  float: right;
  margin-left: 10px;
  span {
    font-size: 18px;
  }
}

.inline-text-edit-buttons {
  margin-top: 10px;
  float: right;
}
